import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "./App";
import { Login } from "./Login";
import { Home } from "./Home";
import { ServiceOrder } from "./ServiceOrder/index.js";
import { ServiceOrderList } from "./ServiceOrderList";
import { ServiceOrderDetail } from "./ServiceOrderDetail";
import { PrivateRoute } from "../components/PrivateRoute";
import { PublicRoute } from "../components/PublicRoute";
import { Test } from "./Test";
import { Results } from "./Results";
import { ManageTest } from "./ManageTest";
import { OperationalAssistants } from "./OperationalAssistants";
import { Specifications } from "./Specifications";
import { Specimens } from "./Specimens";
import { Equipment } from "./Equipment";
import { Providers } from "./Providers";
import { Regulations } from "./Regulations";
import { MixDesign } from "./MixDesign";
import { CreationRequetsMixDesign } from "./MixDesign/components/CreationRequetsMixDesign";
import { Reports } from "./Reports";
import { Users } from "./Users";
import { Approve } from "./Reports/components/Approve";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="service-order">
            <Route
              index
              element={
                <PrivateRoute>
                  <ServiceOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="list"
              element={
                <PrivateRoute>
                  <ServiceOrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="detail/:id"
              element={
                <PrivateRoute>
                  <ServiceOrderDetail />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="test">
            <Route
              index
              element={
                <PrivateRoute>
                  <Test />
                </PrivateRoute>
              }
            />
            <Route
              path="manage-test/:id"
              element={
                <PrivateRoute>
                  <ManageTest />
                </PrivateRoute>
              }
            />
            <Route
              path="results/:id"
              element={
                <PrivateRoute>
                  <Results />
                </PrivateRoute>
              }
            />
            <Route
              path="pending-reports"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              }
            />
            <Route
              path="approve/:id"
              element={
                <PrivateRoute>
                  <Approve />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="specifications"
            element={
              <PrivateRoute>
                <Specifications />
              </PrivateRoute>
            }
          />
          <Route
            path="specimens"
            element={
              <PrivateRoute>
                <Specimens />
              </PrivateRoute>
            }
          />
          <Route
            path="equipment"
            element={
              <PrivateRoute>
                <Equipment />
              </PrivateRoute>
            }
          />
          <Route
            path="mixdesign"
            element={
              <PrivateRoute>
                <MixDesign />
              </PrivateRoute>
            }
          />
          <Route
            path="mixdesign-creation"
            element={
              <PrivateRoute>
                <CreationRequetsMixDesign />
              </PrivateRoute>
            }
          />
          <Route
            path="providers"
            element={
              <PrivateRoute>
                <Providers />
              </PrivateRoute>
            }
          />
          <Route
            path="regulations"
            element={
              <PrivateRoute>
                <Regulations />
              </PrivateRoute>
            }
          />
          <Route
            path="operational-assistants"
            element={
              <PrivateRoute>
                <OperationalAssistants />
              </PrivateRoute>
            }
          />
          <Route
            path="users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
