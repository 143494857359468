import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetResultToNA8 = (id) => {
  const data = useQuery(["useGetResultToNA8", id], () =>
    api.get(`/ensayo/filtro_ensayo_NA8/?id_ensayo=${id}`)
  );

  return data;
};

export default useGetResultToNA8;
