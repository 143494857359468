/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Flex, Text } from "../../../styles/common";
import { theme } from "../../../styles/theme";
import { Select } from "../../../components/Select";
import { Input } from "../../../components/Input";
import { TextArea } from "../../../components/TextArea";
import { Alert, DatePicker, TimePicker, Tooltip } from "antd";
import { Button } from "../../../components/Button";
import { WrapperForm, WrapperLine } from "../styles";
import useGetRegulations from "../../Regulations/hooks/useGetRegulations";
import useGetTest from "../hook/useGetTests";
import deleteIcon from "../../../assets/icons/delete.svg";
import { useSelector } from "react-redux";
import info from "../../../assets/icons/info.svg";
import Table from "../../../components/Table";
import {
  columnsAction,
  concreteColumnsAction,
  asphaltColumnsAction,
} from "../../../utilities/helpers";
import {
  validateArrayTestsConcrete,
  validateArrayTestsAsphalt,
} from "../../../utilities/validations";
import add from "../../../assets/icons/add.svg";
import addDisable from "../../../assets/icons/add-disable.svg";
import deleteDisable from "../../../assets/icons/delete-disable.svg";
import { useMediaQuery } from "react-responsive";
import useGetMixDesign from "../../MixDesign/hooks/useGetMixDesign";

export const Step2 = ({
  samplesInfo,
  setSamplesInfo,
  errorForm,
  setErrorForm,
  handleNext,
  handleAdd,
  workLine,
  id,
  noSamples,
  samplesArray,
  projects,
}) => {
  const appMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const { common } = useSelector((state) => state);
  const { specificationsList, specimensList, providersList } = common;
  const [forceUpdate, setForceUpdate] = useState(false);
  const test = useGetTest();
  const { data: testData, isSuccess: isSuccessTest } = test;
  const regulations = useGetRegulations();
  const { data: regulationsData, isSuccess: isSuccessRegulations } =
    regulations;
  const [regulationsOptions, setRegulationsOptions] = useState([]);
  const mixDesign = useGetMixDesign();
  const { data: mixDesignData, isSuccess: isSuccessMixDesign } = mixDesign;
  const [mixDesignOptions, setMixDesignOptions] = useState([]);
  const [testOptions, setTestOptions] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [amountError, setAmountError] = useState(false);
  const [isMobile, setIsMobile] = useState(appMobile);
  const [testInfo, setTestInfo] = useState({
    options: [
      {
        cantidad_fallo: null,
        testigo_fallo: null,
        edad_fallo: null,
        dia_hora: null,
      },
    ],
    observacion: "",
    ensayo: null,
    normativa: null,
    diagrama_NA9: null,
  });
  const [testErrorForm, setTestErrorForm] = useState({
    cantidad_fallo: { error: false, message: "" },
    testigo_fallo: { error: false, message: "" },
    edad_fallo: { error: false, message: "" },
    dia_hora: { error: false, message: "" },
    observacion: { error: false, message: "" },
    "[0].ensayo": { error: false, message: "" },
    normativa: { error: false, message: "" },
    diagrama_NA9: { error: false, message: "" },
  });

  useEffect(() => {
    setIsMobile(appMobile);
  }, []);

  useEffect(() => {
    if (isSuccessMixDesign) {
      setMixDesignOptions(
        mixDesignData?.data
          .filter((elem) => elem.proyecto === projects)
          .map((ele) => ({
            label: ele.codigo_clasificacion,
            value: ele.codigo_clasificacion,
          }))
      );
    }
  }, [isSuccessMixDesign]);

  useEffect(() => {
    if (workLine && isSuccessTest && samplesInfo.especimen) {
      const matchingSpecimen = specimensList.find(
        (specimen) => specimen.value === samplesInfo.especimen
      );

      if (matchingSpecimen) {
        const specimenCodes =
          matchingSpecimen.test && matchingSpecimen.test.split(";");

        if (specimenCodes) {
          setTestOptions(
            testData?.data
              .filter(
                (elem) =>
                  elem.linea_trabajo === workLine &&
                  specimenCodes.includes(elem.codigo)
              )
              .map((ele) => ({
                label: ele.codigo + " - " + ele.nombre,
                value: ele.id,
                codigo: ele.codigo,
              }))
          );

          setForceUpdate(!forceUpdate);
        }
      }
    }
  }, [isSuccessTest, workLine, samplesInfo.especimen]);

  useEffect(() => {
    if (workLine && isSuccessRegulations && testInfo.ensayo) {
      const matchingTest = testData?.data.find(
        (test) => test.id === testInfo.ensayo
      );
      if (matchingTest) {
        setRegulationsOptions(
          regulationsData?.data
            .filter(
              (elem) =>
                elem.linea_trabajo === workLine &&
                elem.ensayo.includes(matchingTest.codigo)
            )
            .map((ele) => ({
              label: ele.nombre,
              value: ele.nombre,
            }))
        );
        setForceUpdate(!forceUpdate);
      }
    }
  }, [workLine, isSuccessRegulations, testInfo.ensayo]);

  const handleChangeSelect = (e, id) => {
    const newForm = { ...samplesInfo };
    const newErrorForm = { ...errorForm };

    newForm[id] = e;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";

    setSamplesInfo(newForm);
    setErrorForm(newErrorForm);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (e) => {
    const { value, id } = e.target;
    const newForm = { ...samplesInfo };
    const newErrorForm = { ...errorForm };

    newForm[id] = value;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";

    setSamplesInfo(newForm);
    setErrorForm(newErrorForm);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeCheckbox = (e) => {
    const { checked, id } = e.target;
    const newForm = { ...samplesInfo };
    const newErrorForm = { ...errorForm };

    newForm[id] = checked;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";

    setSamplesInfo(newForm);
    setErrorForm(newErrorForm);
    setForceUpdate(!forceUpdate);
  };

  const cumulativeAmountFunc = (tests) => {
    const copyTests = [...tests];
    let sumCantidadFallo = 0;
    let sumTestigoFallo = 0;

    copyTests.forEach((objeto) => {
      sumCantidadFallo += parseInt(objeto.cantidad_fallo);
      sumTestigoFallo += parseInt(objeto.testigo_fallo);
    });

    return Number(sumCantidadFallo) + Number(sumTestigoFallo);
  };

  const cumulativeAmount = cumulativeAmountFunc(samplesInfo.ensayos_realizar);

  const handleAddTest = () => {
    if (workLine === "Línea de concretos") {
      const newTestInfo = testInfo.options.map((ele) => {
        return {
          cantidad_fallo: ele.cantidad_fallo ? ele.cantidad_fallo : 0,
          testigo_fallo: ele.testigo_fallo ? ele.testigo_fallo : 0,
          edad_fallo: ele.edad_fallo ? ele.edad_fallo : 0,
          dia_hora: ele.dia_hora,
          observacion: testInfo.observacion,
          ensayo: testInfo.ensayo,
          normativa: testInfo.normativa,
          diagrama_NA9: null,
        };
      });

      const cumulativeAmountFunc = () => {
        const copyTests = [...newTestInfo];
        let sumCantidadFallo = 0;
        let sumTestigoFallo = 0;

        copyTests.forEach((objeto) => {
          sumCantidadFallo += parseInt(objeto.cantidad_fallo);
          sumTestigoFallo += parseInt(objeto.testigo_fallo);
        });

        return Number(sumCantidadFallo) + Number(sumTestigoFallo);
      };

      const validation = validateArrayTestsConcrete.validate([...newTestInfo], {
        abortEarly: false,
      });

      if (validation.error) {
        const newErrorForm = testErrorForm;
        validation.error.details.forEach((ele) => {
          newErrorForm[ele.context.label].error = true;
          newErrorForm[ele.context.label].message =
            "Campo requerido o formato inválido";
          setTestErrorForm(newErrorForm);
          setForceUpdate(!forceUpdate);
        });
      } else if (
        samplesInfo.cantidad_especimen < cumulativeAmountFunc() ||
        cumulativeAmountFunc() >
          samplesInfo.cantidad_especimen - cumulativeAmount
      ) {
        setAmountError(true);
      } else {
        const newForm = { ...samplesInfo };
        const newError = { ...errorForm };

        newForm.ensayos_realizar = [
          ...newForm.ensayos_realizar,
          ...newTestInfo,
        ];
        newError.ensayos_realizar.error = false;
        newError.ensayos_realizar.message = "";

        setSamplesInfo(newForm);
        setTestInfo({
          options: [
            {
              cantidad_fallo: null,
              testigo_fallo: null,
              edad_fallo: null,
              dia_hora: null,
            },
          ],
          observacion: "",
          ensayo: null,
          normativa: null,
          diagrama_NA9: null,
        });
        setForceUpdate(!forceUpdate);
      }
    } else if (workLine === "Línea de asfaltos") {
      const newTestInfo = testInfo.options.map((ele) => ({
        cantidad_fallo: 0,
        testigo_fallo: 0,
        edad_fallo: 0,
        dia_hora: "",
        observacion: "",
        ensayo: testInfo.ensayo,
        normativa: "",
        diagrama_NA9: testInfo.diagrama_NA9,
      }));

      const validation = validateArrayTestsAsphalt.validate([...newTestInfo], {
        abortEarly: false,
      });

      if (validation.error) {
        const newErrorForm = testErrorForm;
        validation.error.details.forEach((ele) => {
          newErrorForm[ele.context.label].error = true;
          newErrorForm[ele.context.label].message =
            "Campo requerido o formato inválido";
          setTestErrorForm(newErrorForm);
          setForceUpdate(!forceUpdate);
        });
      } else {
        const newForm = { ...samplesInfo };
        const newError = { ...errorForm };

        // Validacion NA1 y NA8
        if (testInfo.ensayo === 8) {
          const containsTest1 = newForm.ensayos_realizar.some(
            (test) => test.ensayo === 1
          );

          if (!containsTest1) {
            const test1 = {
              cantidad_fallo: 0,
              testigo_fallo: 0,
              edad_fallo: 0,
              dia_hora: "",
              observacion: "",
              ensayo: 1,
              normativa: "",
              diagrama_NA9: "",
            };
            newTestInfo.push(test1);
          }
        }

        //Validacion NA2 y NA3
        if (testInfo.ensayo === 3) {
          const containsTest2 = newForm.ensayos_realizar.some(
            (test) => test.ensayo === 2
          );

          if (!containsTest2) {
            const test2 = {
              cantidad_fallo: 0,
              testigo_fallo: 0,
              edad_fallo: 0,
              dia_hora: "",
              observacion: "",
              ensayo: 2,
              normativa: "",
              diagrama_NA9: "",
            };
            newTestInfo.push(test2);
          }
        }

        //Validacion NA2, NA4 y NA5
        if (testInfo.ensayo === 5) {
          const containsTest2 = newForm.ensayos_realizar.some(
            (test) => test.ensayo === 2
          );
          const containsTest4 = newForm.ensayos_realizar.some(
            (test) => test.ensayo === 4
          );

          if (!containsTest2) {
            const test2 = {
              cantidad_fallo: 0,
              testigo_fallo: 0,
              edad_fallo: 0,
              dia_hora: "",
              observacion: "",
              ensayo: 2,
              normativa: "",
              diagrama_NA9: "",
            };
            newTestInfo.push(test2);
          }

          if (!containsTest4) {
            const test4 = {
              cantidad_fallo: 0,
              testigo_fallo: 0,
              edad_fallo: 0,
              dia_hora: "",
              observacion: "",
              ensayo: 4,
              normativa: "",
              diagrama_NA9: "",
            };
            newTestInfo.push(test4);
          }
        }

        //Validacion NA2, NA7 y NA6
        if (testInfo.ensayo === 6) {
          const containsTest2 = newForm.ensayos_realizar.some(
            (test) => test.ensayo === 2
          );
          const containsTest7 = newForm.ensayos_realizar.some(
            (test) => test.ensayo === 7
          );

          if (!containsTest2) {
            const test2 = {
              cantidad_fallo: 0,
              testigo_fallo: 0,
              edad_fallo: 0,
              dia_hora: "",
              observacion: "",
              ensayo: 2,
              normativa: "",
              diagrama_NA9: "",
            };
            newTestInfo.push(test2);
          }

          if (!containsTest7) {
            const test7 = {
              cantidad_fallo: 0,
              testigo_fallo: 0,
              edad_fallo: 0,
              dia_hora: "",
              observacion: "",
              ensayo: 7,
              normativa: "",
              diagrama_NA9: "",
            };
            newTestInfo.push(test7);
          }
        }

        newForm.ensayos_realizar = [
          ...newForm.ensayos_realizar,
          ...newTestInfo,
        ];
        newError.ensayos_realizar.error = false;
        newError.ensayos_realizar.message = "";

        setSamplesInfo(newForm);
        setTestInfo({
          options: [
            {
              cantidad_fallo: null,
              testigo_fallo: null,
              edad_fallo: null,
              dia_hora: null,
            },
          ],
          observacion: "",
          ensayo: null,
          normativa: null,
          diagrama_NA9: null,
        });
        setForceUpdate(!forceUpdate);
      }
    }
  };

  const handleRemoveTest = (indx) => {
    // const newForm = samplesInfo?.ensayos_realizar?.filter(
    //   (ele, index) => index !== indx
    // );

    const newForm = [...samplesInfo.ensayos_realizar];
    const testToRemove = newForm[indx];

    if (testToRemove.ensayo === 1) {
      newForm.splice(indx, 1);
      const indexTest8 = newForm.findIndex((ele) => ele.ensayo === 8);
      if (indexTest8 !== -1) {
        newForm.splice(indexTest8, 1);
      }
    } else if (testToRemove.ensayo === 2) {
      newForm.splice(indx, 1);
      const indexTest3 = newForm.findIndex((ele) => ele.ensayo === 3);
      if (indexTest3 !== -1) {
        newForm.splice(indexTest3, 1);
      }
      const indexTest5 = newForm.findIndex((ele) => ele.ensayo === 5);
      if (indexTest5 !== -1) {
        newForm.splice(indexTest5, 1);
      }
      const indexTest6 = newForm.findIndex((ele) => ele.ensayo === 6);
      if (indexTest6 !== -1) {
        newForm.splice(indexTest6, 1);
      }
    } else if (testToRemove.ensayo === 4) {
      newForm.splice(indx, 1);
      const indexTest5 = newForm.findIndex((ele) => ele.ensayo === 5);
      if (indexTest5 !== -1) {
        newForm.splice(indexTest5, 1);
      }
    } else if (testToRemove.ensayo === 7) {
      newForm.splice(indx, 1);
      const indexTest6 = newForm.findIndex((ele) => ele.ensayo === 6);
      if (indexTest6 !== -1) {
        newForm.splice(indexTest6, 1);
      }
    } else {
      newForm.splice(indx, 1);
    }

    setSamplesInfo({ ...samplesInfo, ensayos_realizar: newForm });
    setForceUpdate(!forceUpdate);
  };

  const handleChangeTest = (event, index) => {
    const { id, value } = event.target;
    const newTest = { ...testInfo };

    const newLine = newTest.options;
    newLine[index][id] = value;

    setAmountError(false);
    setTestInfo(newTest);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeObs = (event) => {
    const { id, value } = event.target;
    const newTest = { ...testInfo };
    const newError = { ...testErrorForm };

    newTest[id] = value;
    newError[id] = { error: false, message: "" };

    setTestInfo(newTest);
    setTestErrorForm(newError);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeTime = (e, id, index) => {
    const newTest = { ...testInfo };

    const newLine = newTest.options;
    newLine[index][id] = e;

    setTestInfo(newTest);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeSelectTest = (e, id) => {
    const newTest = { ...testInfo };
    const newError = { ...testErrorForm };

    newTest[id] = e;
    newError["[0].ensayo"] = { error: false, message: "" };

    setTestInfo(newTest);
    setTestErrorForm(newError);
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    if (samplesInfo?.ensayos_realizar.length > 0) {
      const dataTable = samplesInfo?.ensayos_realizar.map((ele, i) => ({
        key: i,
        ...ele,
        ensayo: testOptions.find((elem) => elem.value === ele.ensayo)?.label,
        acciones: [
          {
            icon: deleteIcon,
            onClick: () => handleRemoveTest(i),
          },
        ],
      }));

      setDataTable(dataTable);
    } else {
      setDataTable([]);
    }
  }, [samplesInfo, testOptions]);

  const hasError = (fields) => {
    return Object.values(fields).some((field) => field.error === true);
  };

  const hasAnyError = hasError(errorForm);

  const handleAddLine = () => {
    const newTest = { ...testInfo };

    newTest.options = [
      ...newTest.options,
      {
        cantidad_fallo: null,
        testigo_fallo: null,
        edad_fallo: null,
        dia_hora: null,
      },
    ];

    setTestInfo(newTest);
    setForceUpdate(!forceUpdate);
  };

  const handleDeleteLine = (index) => {
    const newTest = testInfo?.options?.filter((ele, indx) => indx !== index);

    setTestInfo({ ...testInfo, options: newTest });
    setForceUpdate(!forceUpdate);
  };

  const [fileError, setFileError] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        setFileError("Solo se permiten archivos PDF o imágenes (JPEG, PNG)");
        const newError = { ...testErrorForm };
        const newTest = { ...testInfo };

        newTest["diagrama_NA9"] = null;
        newError[""] = {
          error: true,
          message: "El diagrama es obligatorio para este ensayo.",
        };

        setTestInfo(newTest);
        setTestErrorForm(newError);
        setForceUpdate(!forceUpdate);
      } else {
        setFileError("");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];

          const newTest = { ...testInfo };
          const newError = { ...testErrorForm };

          newTest["diagrama_NA9"] = base64String;
          newError["diagrama_NA9"] = { error: false, message: "" };

          setTestInfo(newTest);
          setTestErrorForm(newError);
          setForceUpdate(!forceUpdate);
        };
      }
    }
  };
  console.log(testInfo);
  console.log(samplesInfo?.ensayos_realizar);
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        m={isMobile ? "20px 0px" : "70px 0 20px 0"}
        direction={isMobile ? "column" : "row"}
        gap={isMobile ? "20px" : "0px"}
        style={{ alignItems: "start" }}
      >
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h6}
          style={{ textAlign: "start" }}
        >
          Ingreso de muestras y ensayos
        </Text>
        <WrapperLine>
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h6}
            color={theme.colors.white}
            style={{ textAlign: "start" }}
          >
            {workLine}
          </Text>
        </WrapperLine>
      </Flex>
      <WrapperForm>
        <Flex direction="column" gap="30px">
          <Text weight={theme.fonts.weight.bold} style={{ textAlign: "start" }}>
            Información de la muestra
          </Text>
          {/* ------------------------------------------------ Linea de concretos ---------------------------------------------------------*/}
          {workLine === "Línea de concretos" && (
            <>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Select
                  label={"Espécimen"}
                  tooltip={"Tipo de muestra"}
                  placeholder={"Seleccione un espécimen"}
                  value={samplesInfo.especimen}
                  options={specimensList}
                  onChange={(e) => handleChangeSelect(e, "especimen")}
                  error={errorForm.especimen?.error}
                  helper={
                    errorForm.especimen?.error
                      ? errorForm.especimen?.message
                      : ""
                  }
                />
                <Input
                  label={"Cantidad de especímen"}
                  tooltip={"Cantidad total de especímen"}
                  placeholder={"Ingrese la cantidad de especimen"}
                  id="cantidad_especimen"
                  type="number"
                  min={1}
                  value={samplesInfo.cantidad_especimen}
                  onChange={handleChange}
                  error={errorForm.cantidad_especimen?.error}
                  helper={
                    errorForm.cantidad_especimen?.error
                      ? errorForm.cantidad_especimen?.message
                      : ""
                  }
                />
              </Flex>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Select
                  label={"Especificaciones"}
                  placeholder={"Selecciona la especificación"}
                  value={samplesInfo.especificacion}
                  options={specificationsList}
                  onChange={(e) => handleChangeSelect(e, "especificacion")}
                  error={errorForm.especificacion?.error}
                  helper={
                    errorForm.especificacion?.error
                      ? errorForm.especificacion?.message
                      : ""
                  }
                />
                <Select
                  label={"Proveedor"}
                  placeholder={"Seleccione el proveedor"}
                  value={samplesInfo.proveedor}
                  options={providersList}
                  onChange={(e) => handleChangeSelect(e, "proveedor")}
                  error={errorForm.proveedor?.error}
                  helper={
                    errorForm.proveedor?.error
                      ? errorForm.proveedor?.message
                      : ""
                  }
                />
              </Flex>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Input
                  label={"Dirección"}
                  placeholder={"Ingrese la dirección"}
                  id="georeferenciacion"
                  value={samplesInfo.georeferenciacion}
                  onChange={handleChange}
                  error={errorForm.georeferenciacion?.error}
                  helper={
                    errorForm.georeferenciacion?.error
                      ? errorForm.georeferenciacion?.message
                      : ""
                  }
                />
                <TextArea
                  label={"Complemento de la dirección"}
                  placeholder={"Ingrese el complemento de la dirección"}
                  id="descripcion_georeferenciacion"
                  value={samplesInfo.descripcion_georeferenciacion}
                  onChange={handleChange}
                  error={errorForm.descripcion_georeferenciacion?.error}
                  helper={
                    errorForm.descripcion_georeferenciacion?.error
                      ? errorForm.descripcion_georeferenciacion?.message
                      : ""
                  }
                />
              </Flex>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Input
                  label={"Número de remisión"}
                  placeholder={"Ingrese el número de remisión"}
                  id="numero_remision"
                  value={samplesInfo.numero_remision}
                  onChange={handleChange}
                  error={errorForm.numero_remision?.error}
                  helper={
                    errorForm.numero_remision?.error
                      ? errorForm.numero_remision?.message
                      : ""
                  }
                />
                <TextArea
                  label={"Localización de la muestra en obra"}
                  placeholder={
                    "Ingrese la localización dónde se encuentra la muestra"
                  }
                  id="locacion"
                  value={samplesInfo.locacion}
                  onChange={handleChange}
                  error={errorForm.locacion?.error}
                  helper={
                    errorForm.locacion?.error ? errorForm.locacion?.message : ""
                  }
                />
              </Flex>
              {workLine === "Línea de concretos" && (
                <>
                  <Flex
                    gap={isMobile ? "20px" : "60px"}
                    direction={isMobile ? "column" : "row"}
                  >
                    <Flex direction="column">
                      <Text
                        size={theme.fonts.size.sm}
                        color={theme.colors.dark}
                        weight={theme.fonts.weight.medium}
                        mb="8px"
                        style={{ textAlign: "start" }}
                      >
                        Fecha de toma de muestras
                      </Text>
                      <DatePicker
                        placeholder="Seleccione una fecha"
                        value={samplesInfo.fecha_toma}
                        onChange={(e) => handleChangeSelect(e, "fecha_toma")}
                        status={errorForm.fecha_toma?.error ? "error" : ""}
                        format={"YYYY-MM-DD"}
                        helper={
                          errorForm.fecha_toma?.error
                            ? errorForm.fecha_toma?.message
                            : ""
                        }
                        style={{ width: "300px", height: "40px" }}
                      />
                      {errorForm.fecha_toma?.error && (
                        <Text
                          size={theme.fonts.size.sm}
                          color={theme.colors.red}
                          mt="2px"
                          style={{ textAlign: "-webkit-left" }}
                        >
                          {errorForm.fecha_toma?.message}
                        </Text>
                      )}
                    </Flex>
                    <Flex direction="column">
                      <Text
                        size={theme.fonts.size.sm}
                        color={theme.colors.dark}
                        weight={theme.fonts.weight.medium}
                        mb="8px"
                        style={{ textAlign: "start" }}
                      >
                        Hora de toma de muestras
                      </Text>
                      <TimePicker
                        placeholder="Seleccione una hora"
                        value={samplesInfo.hora_toma}
                        onChange={(e) => handleChangeSelect(e, "hora_toma")}
                        status={errorForm.hora_toma?.error ? "error" : ""}
                        format={"HH:mm"}
                        style={{ width: "300px", height: "40px" }}
                      />
                      {errorForm.hora_toma?.error && (
                        <Text
                          size={theme.fonts.size.sm}
                          color={theme.colors.red}
                          mt="2px"
                          style={{ textAlign: "-webkit-left" }}
                        >
                          {errorForm.hora_toma?.message}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    gap={isMobile ? "20px" : "60px"}
                    direction={isMobile ? "column" : "row"}
                  >
                    <Input
                      label={"Código mezcla"}
                      placeholder={"Ingrese el código de la mezcla"}
                      id="codigo_mezcla"
                      value={samplesInfo.codigo_mezcla}
                      onChange={handleChange}
                      error={errorForm.codigo_mezcla?.error}
                      helper={
                        errorForm.codigo_mezcla?.error
                          ? errorForm.codigo_mezcla?.message
                          : ""
                      }
                    />
                    <Input
                      label={"Descripción mezcla"}
                      placeholder={"Ingrese la descripción código de la mezcla"}
                      id="descripcion_mezcla"
                      value={samplesInfo.descripcion_mezcla}
                      onChange={handleChange}
                      error={errorForm.descripcion_mezcla?.error}
                      helper={
                        errorForm.descripcion_mezcla?.error
                          ? errorForm.descripcion_mezcla?.message
                          : ""
                      }
                    />
                  </Flex>
                  <Flex>
                    <Input
                      label={"Resistencia nominal (MPa)"}
                      placeholder={"Ingrese la resistencia nominal"}
                      id="resistencia_nominal"
                      type="number"
                      min={1}
                      value={samplesInfo.resistencia_nominal}
                      onChange={handleChange}
                      error={errorForm.resistencia_nominal?.error}
                      helper={
                        errorForm.resistencia_nominal?.error
                          ? errorForm.resistencia_nominal?.message
                          : ""
                      }
                    />
                  </Flex>
                </>
              )}
              {(workLine === "Línea de concretos" ||
                workLine === "Línea de asfaltos") && (
                <Flex
                  gap={isMobile ? "20px" : "60px"}
                  direction={isMobile ? "column" : "row"}
                >
                  {workLine === "Línea de concretos" && (
                    <Input
                      label={'Slump (Pulgadas ")'}
                      placeholder={"Ingrese el slump"}
                      id="slum"
                      type="number"
                      min={1}
                      value={samplesInfo.slum}
                      onChange={handleChange}
                      error={errorForm.slum?.error}
                      helper={
                        errorForm.slum?.error ? errorForm.slum?.message : ""
                      }
                    />
                  )}
                  {(workLine === "Línea de concretos" ||
                    workLine === "Línea de asfaltos") && (
                    <Input
                      label={"Temperatura (°C)"}
                      placeholder={"Ingrese la temperatura"}
                      id="temperatura"
                      type="number"
                      min={1}
                      value={samplesInfo.temperatura}
                      onChange={handleChange}
                      error={errorForm.temperatura?.error}
                      helper={
                        errorForm.temperatura?.error
                          ? errorForm.temperatura?.message
                          : ""
                      }
                    />
                  )}
                </Flex>
              )}

              <Flex>
                <TextArea
                  label={"Observación"}
                  placeholder={"Ingrese la observación"}
                  id="observacion"
                  value={samplesInfo.observacion}
                  onChange={handleChange}
                  error={errorForm.observacion?.error}
                  helper={
                    errorForm.observacion?.error
                      ? errorForm.observacion?.message
                      : ""
                  }
                  width={isMobile ? "300px" : "660px"}
                />
              </Flex>
              <Flex align="center" gap="10px">
                <Text
                  weight={theme.fonts.weight.bold}
                  style={{ textAlign: "start" }}
                >
                  Información de los ensayos
                </Text>
                <Tooltip
                  title={
                    "Por favor proporcione los tipos de ensayos para el especímen seleccionado"
                  }
                  color={theme.colors.gray}
                >
                  <img alt="info" src={info} height={"18px"} />
                </Tooltip>
              </Flex>
              <Text size={theme.fonts.size.h5} style={{ textAlign: "start" }}>
                Cantidad de especímen disponible:{" "}
                {`${samplesInfo.cantidad_especimen - cumulativeAmount}`}
              </Text>
              {errorForm.ensayos_realizar?.error && (
                <Alert
                  type="error"
                  message="Debes añadir al menos un ensayo"
                  style={{ width: "max-content" }}
                />
              )}
              <Flex>
                <Select
                  label={"Tipo de ensayo"}
                  placeholder={"Seleccione el tipo de ensayo"}
                  value={testInfo.ensayo}
                  options={testOptions}
                  onChange={(e) => handleChangeSelectTest(e, "ensayo")}
                  error={testErrorForm["[0].ensayo"]?.error}
                  helper={
                    testErrorForm["[0].ensayo"]?.error
                      ? testErrorForm["[0].ensayo"]?.message
                      : ""
                  }
                  width={isMobile ? "300px" : "660px"}
                />
              </Flex>
              {workLine === "Línea de concretos" && (
                <Flex>
                  <Select
                    label={"Normativa"}
                    placeholder={"Seleccione la normativa"}
                    value={testInfo.normativa}
                    options={regulationsOptions}
                    onChange={(e) => handleChangeSelectTest(e, "normativa")}
                    error={testErrorForm["[0].normativa"]?.error}
                    helper={
                      testErrorForm["[0].normativa"]?.error
                        ? testErrorForm["[0].normativa"]?.message
                        : ""
                    }
                  />
                </Flex>
              )}

              {testInfo.options.map((ele, indx) => (
                <Flex
                  gap={isMobile ? "20px" : "40px"}
                  direction={isMobile ? "column" : "row"}
                >
                  {(workLine === "Línea de concretos" ||
                    workLine === "Línea de asfaltos") && (
                    <Input
                      label={"Cantidad"}
                      tooltip={
                        workLine === "Línea de concretos"
                          ? "Cantidad de especímen por edad de falla"
                          : "Cantidad de briquetas por cada ensayo"
                      }
                      placeholder={
                        workLine === "Línea de concretos"
                          ? "Ingrese la cantidad por edad de falla"
                          : "Ingrese la cantidad de briquetas por cada ensayo"
                      }
                      id="cantidad_fallo"
                      type="number"
                      min={0}
                      value={ele.cantidad_fallo}
                      onChange={(e) => handleChangeTest(e, indx)}
                      width={
                        isMobile
                          ? "300px"
                          : workLine === "Línea de concretos"
                          ? "150px"
                          : "300px"
                      }
                    />
                  )}
                  {workLine === "Línea de concretos" && (
                    <>
                      <Input
                        label={"Edad de falla"}
                        placeholder={"Ingrese la edad de falla"}
                        id="edad_fallo"
                        type="number"
                        min={0}
                        value={ele.edad_fallo}
                        onChange={(e) => handleChangeTest(e, indx)}
                        width={isMobile ? "300px" : "150px"}
                      />
                      <Select
                        label={"Unidad de tiempo"}
                        tooltip={"Unidad de tiempo de la edad de falla"}
                        placeholder={"Seleccione la unidad de tiempo"}
                        value={ele.dia_hora}
                        options={[
                          { value: "Horas", label: "Horas" },
                          { value: "Días", label: "Días" },
                        ]}
                        onChange={(e) => handleChangeTime(e, "dia_hora", indx)}
                        width={isMobile ? "300px" : "150px"}
                      />
                      <Input
                        label={"Testigo"}
                        placeholder={"Ingrese el testigo"}
                        id="testigo_fallo"
                        type="number"
                        min={0}
                        value={ele.testigo_fallo}
                        onChange={(e) => handleChangeTest(e, indx)}
                        width={isMobile ? "300px" : "150px"}
                      />
                      <Flex gap="10px" m="28px 0 0 0">
                        <Button
                          background={theme.colors.white}
                          hover={theme.colors.white}
                          style={{ padding: "0px" }}
                          onClick={handleAddLine}
                          disabled={indx !== testInfo.options.length - 1}
                        >
                          <img
                            alt="add"
                            src={
                              indx !== testInfo.options.length - 1
                                ? addDisable
                                : add
                            }
                            height={"28px"}
                          />
                        </Button>
                        <Button
                          background={theme.colors.white}
                          hover={theme.colors.white}
                          style={{ padding: "0px" }}
                          onClick={() => handleDeleteLine(indx)}
                          disabled={indx === 0}
                        >
                          <img
                            alt="delete"
                            src={indx === 0 ? deleteDisable : deleteIcon}
                            height={"28px"}
                          />
                        </Button>
                      </Flex>
                    </>
                  )}
                </Flex>
              ))}

              <Flex
                gap={isMobile ? "20px" : "60px"}
                align={isMobile ? "start" : "center"}
                direction={isMobile ? "column" : "row"}
              >
                <TextArea
                  label={"Observación"}
                  placeholder={"Ingrese observaciones"}
                  id="observacion"
                  value={testInfo.observacion}
                  onChange={handleChangeObs}
                  error={testErrorForm.observacion?.error}
                  helper={
                    testErrorForm.observacion?.error
                      ? testErrorForm.observacion?.message
                      : ""
                  }
                />
                <Flex gap="10px">
                  <Button
                    background={theme.colors.orange}
                    hover={theme.colors.orangeHover}
                    onClick={handleAddTest}
                    style={{ width: "160px" }}
                    disabled={
                      samplesInfo.cantidad_especimen <= cumulativeAmount
                    }
                  >
                    {testInfo.options.length > 1
                      ? "Añadir ensayos"
                      : "Añadir ensayo"}
                  </Button>
                </Flex>
              </Flex>
              {amountError && (
                <Alert
                  type="error"
                  message="La cantidad de especímen seleccionada es mayor a la cantidad total"
                  style={{ width: isMobile ? "300px" : "max-content" }}
                />
              )}
              {samplesInfo?.ensayos_realizar.length > 0 && (
                <Flex m="10px 0 0 0">
                  <Table
                    columns={
                      workLine === "Línea de concretos"
                        ? concreteColumnsAction
                        : columnsAction
                    }
                    data={dataTable}
                    pagination={false}
                    locale={{
                      emptyText: "No hay datos",
                    }}
                  />
                </Flex>
              )}
            </>
          )}
          {/* ------------------------------------------------ Linea de asfaltos ---------------------------------------------------------*/}
          {workLine === "Línea de asfaltos" && (
            <>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Select
                  label={"Tipo de espécimen"}
                  tooltip={"Tipo de muestra"}
                  placeholder={"Seleccionar"}
                  value={samplesInfo.especimen}
                  options={specimensList}
                  onChange={(e) => handleChangeSelect(e, "especimen")}
                  error={errorForm.especimen?.error}
                  helper={
                    errorForm.especimen?.error
                      ? errorForm.especimen?.message
                      : ""
                  }
                  width={"200px"}
                />
                <Input
                  label={"Cantidad de espécimen"}
                  tooltip={"Cantidad total de muestra"}
                  placeholder={"Ingresar"}
                  id="cantidad_especimen"
                  type="number"
                  min={1}
                  value={samplesInfo.cantidad_especimen}
                  onChange={handleChange}
                  error={errorForm.cantidad_especimen?.error}
                  helper={
                    errorForm.cantidad_especimen?.error
                      ? errorForm.cantidad_especimen?.message
                      : ""
                  }
                  width={"170px"}
                />
                <Select
                  label={"Diseño de mezcla"}
                  placeholder={"Seleccionar"}
                  value={samplesInfo.clasificacion}
                  options={mixDesignOptions}
                  onChange={(e) => handleChangeSelect(e, "clasificacion")}
                  error={errorForm.clasificacion?.error}
                  helper={
                    errorForm.clasificacion?.error
                      ? errorForm.clasificacion?.message
                      : ""
                  }
                  width={"170px"}
                />
                <Input
                  label={"¿Aplicar formula de trabajo?"}
                  id="usar_formula_trabajo"
                  type="Checkbox"
                  value={samplesInfo.usar_formula_trabajo}
                  onChange={handleChangeCheckbox}
                  error={errorForm.usar_formula_trabajo?.error}
                  helper={
                    errorForm.usar_formula_trabajo?.error
                      ? errorForm.usar_formula_trabajo?.message
                      : ""
                  }
                  width={"170px"}
                />
              </Flex>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Input
                  label={"Temperatura"}
                  tooltip={"Temperatura de compactación (°C)"}
                  placeholder={"Ingresar"}
                  id="temperatura"
                  type="number"
                  min={1}
                  value={samplesInfo.temperatura}
                  onChange={handleChange}
                  error={errorForm.temperatura?.error}
                  helper={
                    errorForm.temperatura?.error
                      ? errorForm.temperatura?.message
                      : ""
                  }
                  width={"200px"}
                />
                <Input
                  label={"Abscisado Inicial"}
                  placeholder={"Ingresar"}
                  id="abscisado_inicial"
                  value={samplesInfo.abscisado_inicial}
                  onChange={handleChange}
                  error={errorForm.abscisado_inicial?.error}
                  helper={
                    errorForm.abscisado_inicial?.error
                      ? errorForm.abscisado_inicial?.message
                      : ""
                  }
                  width={"170px"}
                />
                <Input
                  label={"Abscisado Final"}
                  placeholder={"Ingresar"}
                  id="abscisado_final"
                  value={samplesInfo.abscisado_final}
                  onChange={handleChange}
                  error={errorForm.abscisado_final?.error}
                  helper={
                    errorForm.abscisado_final?.error
                      ? errorForm.abscisado_final?.message
                      : ""
                  }
                  width={"170px"}
                />
                <Select
                  label={"Proveedor"}
                  placeholder={"Seleccionar"}
                  value={samplesInfo.proveedor}
                  options={providersList}
                  onChange={(e) => handleChangeSelect(e, "proveedor")}
                  error={errorForm.proveedor?.error}
                  helper={
                    errorForm.proveedor?.error
                      ? errorForm.proveedor?.message
                      : ""
                  }
                  width={"200px"}
                />
              </Flex>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Select
                  label={"Especificación aplicable"}
                  placeholder={"Seleccionar"}
                  value={samplesInfo.especificacion}
                  options={specificationsList}
                  onChange={(e) => handleChangeSelect(e, "especificacion")}
                  error={errorForm.especificacion?.error}
                  helper={
                    errorForm.especificacion?.error
                      ? errorForm.especificacion?.message
                      : ""
                  }
                  width={"200px"}
                />
                <Flex direction="column">
                  <Text
                    size={theme.fonts.size.sm}
                    color={theme.colors.dark}
                    weight={theme.fonts.weight.medium}
                    mb="8px"
                    style={{ textAlign: "start" }}
                  >
                    Fecha de toma de muestras
                  </Text>
                  <DatePicker
                    placeholder="Seleccionar"
                    value={samplesInfo.fecha_toma}
                    onChange={(e) => handleChangeSelect(e, "fecha_toma")}
                    status={errorForm.fecha_toma?.error ? "error" : ""}
                    format={"YYYY-MM-DD"}
                    helper={
                      errorForm.fecha_toma?.error
                        ? errorForm.fecha_toma?.message
                        : ""
                    }
                    style={{ width: "170px", height: "40px" }}
                  />
                  {errorForm.fecha_toma?.error && (
                    <Text
                      size={theme.fonts.size.sm}
                      color={theme.colors.red}
                      mt="2px"
                      style={{ textAlign: "-webkit-left" }}
                    >
                      {errorForm.fecha_toma?.message}
                    </Text>
                  )}
                </Flex>
                <Flex direction="column">
                  <Text
                    size={theme.fonts.size.sm}
                    color={theme.colors.dark}
                    weight={theme.fonts.weight.medium}
                    mb="8px"
                    style={{ textAlign: "start" }}
                  >
                    Hora de toma de muestras
                  </Text>
                  <TimePicker
                    placeholder="Seleccionar "
                    value={samplesInfo.hora_toma}
                    onChange={(e) => handleChangeSelect(e, "hora_toma")}
                    status={errorForm.hora_toma?.error ? "error" : ""}
                    format={"HH:mm"}
                    style={{ width: "170px", height: "40px" }}
                  />
                  {errorForm.hora_toma?.error && (
                    <Text
                      size={theme.fonts.size.sm}
                      color={theme.colors.red}
                      mt="2px"
                      style={{ textAlign: "-webkit-left" }}
                    >
                      {errorForm.hora_toma?.message}
                    </Text>
                  )}
                </Flex>
                <TextArea
                  label={"Localización de la muestra en obra"}
                  placeholder={"Ingresar"}
                  id="locacion"
                  value={samplesInfo.locacion}
                  onChange={handleChange}
                  error={errorForm.locacion?.error}
                  helper={
                    errorForm.locacion?.error ? errorForm.locacion?.message : ""
                  }
                  width={"200px"}
                />
              </Flex>
              <Flex
                gap={isMobile ? "20px" : "60px"}
                direction={isMobile ? "column" : "row"}
              >
                <Input
                  label={"Dirección"}
                  placeholder={"Ingrese la dirección"}
                  id="georeferenciacion"
                  value={samplesInfo.georeferenciacion}
                  onChange={handleChange}
                  error={errorForm.georeferenciacion?.error}
                  helper={
                    errorForm.georeferenciacion?.error
                      ? errorForm.georeferenciacion?.message
                      : ""
                  }
                  width={"200px"}
                />
                <TextArea
                  label={"Complemento dirección"}
                  placeholder={"Ingresar"}
                  id="descripcion_georeferenciacion"
                  value={samplesInfo.descripcion_georeferenciacion}
                  onChange={handleChange}
                  error={errorForm.descripcion_georeferenciacion?.error}
                  helper={
                    errorForm.descripcion_georeferenciacion?.error
                      ? errorForm.descripcion_georeferenciacion?.message
                      : ""
                  }
                  width={"170px"}
                />
                <TextArea
                  label={"Observaciones"}
                  placeholder={"Ingresar"}
                  id="observacion"
                  value={samplesInfo.observacion}
                  onChange={handleChange}
                  error={errorForm.observacion?.error}
                  helper={
                    errorForm.observacion?.error
                      ? errorForm.observacion?.message
                      : ""
                  }
                  width={isMobile ? "300px" : "450px"}
                />
              </Flex>
              <Flex align="center" gap="10px">
                <Text
                  weight={theme.fonts.weight.bold}
                  style={{ textAlign: "start" }}
                >
                  Información de los ensayos
                </Text>
                <Tooltip
                  title={
                    "Por favor proporcione los tipos de ensayos para el especímen seleccionado"
                  }
                  color={theme.colors.gray}
                >
                  <img alt="info" src={info} height={"18px"} />
                </Tooltip>
              </Flex>
              {errorForm.ensayos_realizar?.error && (
                <Alert
                  type="error"
                  message="Debes añadir al menos un ensayo"
                  style={{ width: "max-content" }}
                />
              )}
              <Flex>
                <Select
                  label={"Tipo de ensayo"}
                  placeholder={"Seleccione el tipo de ensayo"}
                  value={testInfo.ensayo}
                  options={testOptions}
                  onChange={(e) => handleChangeSelectTest(e, "ensayo")}
                  error={testErrorForm["[0].ensayo"]?.error}
                  helper={
                    testErrorForm["[0].ensayo"]?.error
                      ? testErrorForm["[0].ensayo"]?.message
                      : ""
                  }
                  width={isMobile ? "300px" : "700px"}
                />
                {testInfo.ensayo === 9 && (
                  <>
                    <Input
                      id="diagrama_NA9"
                      value={testInfo.diagrama_NA9}
                      error={testErrorForm.diagrama_NA9?.error}
                      helper={
                        testErrorForm.diagrama_NA9?.error
                          ? testErrorForm.diagrama_NA9?.message
                          : ""
                      }
                      style={{ display: "none" }}
                    />
                    <div>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        lang="es"
                      />
                      {fileError && <p style={{ color: "red" }}>{fileError}</p>}
                    </div>
                  </>
                )}

                <Button
                  background={theme.colors.orange}
                  hover={theme.colors.orangeHover}
                  onClick={handleAddTest}
                  style={{ margin: "15px 0 0 15px", width: "160px" }}
                  disabled={
                    !testInfo.ensayo ||
                    samplesInfo?.ensayos_realizar?.some(
                      (ensayo) => ensayo.ensayo === testInfo.ensayo
                    ) ||
                    (testInfo.ensayo === 9 && testInfo.diagrama_NA9 == null)
                  }
                >
                  Añadir ensayo
                </Button>
              </Flex>
              {samplesInfo?.ensayos_realizar.length > 0 && (
                <Flex m="10px 0 0 0">
                  <Table
                    columns={
                      workLine === "Línea de asfaltos"
                        ? asphaltColumnsAction
                        : columnsAction
                    }
                    data={dataTable}
                    pagination={false}
                    locale={{
                      emptyText: "No hay datos",
                    }}
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </WrapperForm>
      <Flex m={"40px 0 0 0"} direction="column">
        {hasAnyError && (
          <Alert
            type="error"
            message="Debes completar todos los campos para añadir la muestra"
            style={{
              width: isMobile ? "300px" : "max-content",
              marginBottom: "10px",
              textAlign: "left",
            }}
          />
        )}
        <Flex
          justify="end"
          direction={isMobile ? "column" : "row"}
          gap={isMobile ? "20px" : "0px"}
          m="0 0 20px 0"
        >
          <Flex gap="20px" align="center" m="0 10px 0 0">
            <Tooltip
              title={
                "Debes llenar todos los datos para poder añadir una muestra"
              }
              color={theme.colors.gray}
            >
              <img alt="info" src={info} />
            </Tooltip>
            <Button
              background={theme.colors.orange}
              hover={theme.colors.orangeHover}
              onClick={handleAdd}
            >
              {id && !noSamples ? "Guardar cambios" : "Añadir muestra"}
            </Button>
          </Flex>
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={handleNext}
            style={{ width: "150px" }}
            disabled={samplesArray.length === 0}
          >
            Siguiente
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
