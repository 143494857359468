import React, { useEffect, useState, useRef } from "react";
import Modal from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Flex } from "../../../styles/common";
import { Controller } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Password } from "../../../components/Password";
import { passwordPattern } from "../../../utilities/validations";

export const ModalUser = ({
  openModal,
  handleClose,
  onSubmit,
  formState,
  control,
  watch,
  isLoading,
  isEditing,
  signatureImage,
  setValue,
}) => {
  const { isDirty, isValid, errors } = formState;
  const [saveSignatureImage, setSaveSignatureImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (signatureImage) {
      setSaveSignatureImage(signatureImage);
    }
    if (!isEditing) {
      setSaveSignatureImage(null);
    }
    if (!openModal) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [openModal]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setSaveSignatureImage(base64String);
        setValue("firma_imagen", base64String);
      };
    }
  };

  return (
    <Modal
      align="center"
      isOpen={openModal}
      onClose={handleClose}
      padding={36}
      width={"min-content"}
    >
      <Modal.Header
        padding="0 0 16px 0"
        title="Crear usuario"
        size={theme.fonts.size.h5}
      />
      <Modal.Body margin="16px 0 24px 0" minHeight={600}>
        <Flex direction="column" justify="center" gap="18px">
          <Controller
            name="username"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="username"
                placeholder={"Usuario"}
                error={!!errors.username}
                helper={
                  errors?.username && errors?.username?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="nombres"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="nombres"
                placeholder={"Nombres"}
                error={!!errors.nombres}
                helper={
                  errors?.nombres && errors?.nombres?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="apellidos"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="apellidos"
                placeholder={"Apellidos"}
                error={!!errors.apellidos}
                helper={
                  errors?.apellidos && errors?.apellidos?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={
              !isEditing
                ? { required: true, pattern: passwordPattern }
                : { required: true }
            }
            render={({ field }) => (
              <Password
                id="password"
                placeholder={"Contraseña"}
                error={!!errors.password}
                helper={
                  errors?.password && errors?.password?.type === "required"
                    ? "Campo requerido"
                    : errors?.password && errors?.password?.type === "pattern"
                    ? "Formato inválido"
                    : ""
                }
                {...field}
                disabled={isEditing}
              />
            )}
          />
          <Controller
            name="firma_imagen"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                id="firma_imagen"
                placeholder={"firma_imagen"}
                error={!!errors.firma_imagen}
                value={saveSignatureImage}
                helper={
                  errors?.firma_imagen &&
                  errors?.firma_imagen?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                hidden={true}
              />
            )}
          />

          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ marginBottom: "10px" }}
              ref={fileInputRef}
              error={!!errors.firma_imagen}
            />
            {saveSignatureImage && (
              <img
                src={"data:image/png;base64," + saveSignatureImage}
                alt="Firma"
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </Flex>
        <Flex m="30px 0 0 0" align="center" justify="center" gap={"24px"}>
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={!isValid ? theme.colors.gray : theme.colors.orangeHover}
            disabled={!isValid}
            isLoading={isLoading}
            onClick={() =>
              onSubmit({
                nombres: watch("nombres"),
                apellidos: watch("apellidos"),
                username: watch("username"),
                password: watch("password"),
                firma_imagen: watch("firma_imagen"),
              })
            }
          >
            {isEditing ? "Actualizar" : "Guardar"}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
