import React, { useState, useEffect } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { useLocation, useParams } from "react-router-dom";
import { NC1 } from "./components/ConcreteLine/NC1";
import { NC2 } from "./components/ConcreteLine/NC2";
import { NC3 } from "./components/ConcreteLine/NC3";
import { NC4 } from "./components/ConcreteLine/NC4";
import { NA1 } from "./components/AsphaltLine/NA1";
import { NA2 } from "./components/AsphaltLine/NA2";
import { NA3 } from "./components/AsphaltLine/NA3";
import { NA4 } from "./components/AsphaltLine/NA4";
import { NA7 } from "./components/AsphaltLine/NA7";
import { NA8 } from "./components/AsphaltLine/NA8";
import { NA9 } from "./components/AsphaltLine/NA9";
import { NA10 } from "./components/AsphaltLine/NA10";
import { theme } from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { getUser } from "../../utilities/helpers";
import { Link } from "./../../components/Link";
import arrowBack from "./../../assets/icons/arrow-back.svg";
import useGetTest from "../ServiceOrder/hook/useGetTests";

export const ManageTest = () => {
  const { id } = useParams();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const test = searchParams.get("test");
  const amount = searchParams.get("amount");
  const specification = searchParams.get("specification");
  const status = searchParams.get("status");
  const isSaved = searchParams.get("isSaved");
  const workLine = searchParams.get("workLine");
  const navigate = useNavigate();
  const { data: testData, isSuccess: isSuccessTest } = useGetTest();
  const [testName, setTestName] = useState();

  useEffect(() => {
    if (testData && testData.data) {
      const testNameData = testData.data.find((elem) => elem.codigo === test);
      if (testNameData) {
        setTestName(testNameData.nombre);
      }
    }
  }, [testData, isSuccessTest]);

  const currentTest = (test) => {
    switch (test) {
      case "NC1":
        return (
          <NC1
            testName={testName}
            amount={amount}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NC2":
        return (
          <NC2
            testName={testName}
            amount={amount}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NC3":
        return (
          <>
            <NC3
              testName={testName}
              amount={amount}
              specification={specification}
              id={id}
              status={status}
              isSaved={isSaved}
            />
          </>
        );
      case "NC4":
        return (
          <>
            <NC4
              testName={testName}
              amount={amount}
              specification={specification}
              id={id}
              status={status}
              isSaved={isSaved}
            />
          </>
        );
      case "NA1":
        return (
          <NA1
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA2":
        return (
          <NA2
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA3":
        return (
          <NA3
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA4":
        return (
          <NA4
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA7":
        return (
          <NA7
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA8":
        return (
          <NA8
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA9":
        return (
          <NA9
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      case "NA10":
        return (
          <NA10
            testName={testName}
            amount={1}
            specification={specification}
            id={id}
            status={status}
            isSaved={isSaved}
          />
        );
      default:
        return "";
    }
  };

  const handleResult = () => {
    navigate(`/test/results/${id}?workLine=${workLine}`);
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Link onClick={handleBackPage} icon={<img alt="back" src={arrowBack} />}>
        Atras
      </Link>
      <Text weight={theme.fonts.weight.bold} style={{ alignSelf: "center" }}>
        Toma de datos
      </Text>
      {currentTest(test)}
      {status === "ER" && getUser().permissions.includes(23) && (
        <Flex justify="center">
          <Button
            style={{
              marginTop: "20px",
            }}
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={handleResult}
          >
            Ver resultados del ensayo
          </Button>
        </Flex>
      )}
    </Container>
  );
};
