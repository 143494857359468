import api from "../../../core/api";
import { useMutation } from "react-query";

const useCreateMixDesign = () => {
  const mutation = useMutation((formData) => {
    return api.post("/orden_servicio/tipo_clasificacion/", formData);
  });
  return mutation;
};
export default useCreateMixDesign;
