import React from "react";
import Modal from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Flex, Text } from "../../../styles/common";
import { Controller } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";

export const ModalSpecimens = ({
  openModal,
  handleClose,
  onSubmit,
  formState,
  control,
  watch,
  isLoading,
  isEditing,
  workLineOptions,
  testOptions,
}) => {
  const { isDirty, isValid, errors } = formState;

  return (
    <Modal
      align="center"
      isOpen={openModal}
      onClose={handleClose}
      padding={36}
      width={"min-content"}
    >
      <Modal.Header
        padding="0 0 16px 0"
        title={isEditing ? "Actualizar especimen" : "Crear especimen"}
        size={theme.fonts.size.h5}
      />
      <Modal.Body margin="16px 0 24px 0" minHeight={600}>
        <Flex direction="column" justify="center" gap="18px">
          <Controller
            name="nombre"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="nombre"
                placeholder={"Nombre"}
                error={!!errors.nombre}
                helper={
                  errors?.nombre && errors?.nombre?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="linea_trabajo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="linea_trabajo"
                error={!!errors.linea_trabajo}
                helper={
                  errors?.linea_trabajo &&
                  errors?.linea_trabajo?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Línea de trabajo
                </option>
                {workLineOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="descripcion"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="descripcion"
                placeholder={"Descripción"}
                error={!!errors.descripcion}
                helper={
                  errors?.descripcion &&
                  errors?.descripcion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Text
            size={theme.fonts.size.x}
            color={theme.colors.dark}
            style={{ textAlign: "start" }}
          >
            Tipo de ensayo:
          </Text>
          <Controller
            name="ensayo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="ensayo"
                error={!!errors.ensayo}
                mode="multiple"
                helper={
                  errors?.ensayo && errors?.ensayo?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Tipo de ensayo
                </option>
                {testOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
        </Flex>
        <Flex m="30px 0 0 0" align="center" justify="center" gap={"24px"}>
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={
              !isDirty || !isValid
                ? theme.colors.gray
                : theme.colors.orangeHover
            }
            disabled={!isDirty || !isValid}
            isLoading={isLoading}
            onClick={() => {
              const ensayo =
                watch("ensayo") && watch("ensayo").length > 0
                  ? watch("ensayo").join(";")
                  : "";
              onSubmit({
                nombre: watch("nombre"),
                descripcion: watch("descripcion"),
                linea_trabajo: watch("linea_trabajo"),
                ensayo: ensayo.startsWith(";") ? ensayo.slice(1) : ensayo,
              });
            }}
          >
            {isEditing ? "Actualizar" : "Guardar"}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
