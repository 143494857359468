import React, { useEffect, useState } from "react";
import useGetMixDesign from "./hooks/useGetMixDesign";
import useGetGranulometricBandCodesSpecification from "./hooks/useGetGranulometricBandCodesSpecification";
import useDeleteMixDesign from "./hooks/useDeleteMixDesign";
import useCreateMixDesign from "./hooks/useCreateMixDesign";
import useUpdateMixDesign from "./hooks/useUpdateMixDesign";
import { Container, Flex, Text } from "../../styles/common";
import Table from "../../components/Table";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import Skeleton from "../../components/Skeleton";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import toast from "react-hot-toast";
import { Tooltip } from "antd";
import { useForm } from "react-hook-form";
import { ModalMixDesign } from "./components/ModalMixDesign";
import useGetClients from "../ServiceOrder/hook/getClients";
import useGetSpecifications from "../Specifications/hooks/useGetSpecifications";
import file from "../../assets/icons/file.svg";
import fileDisable from "../../assets/icons/file-disable.svg";

export const MixDesign = () => {
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [totalItems, setTotalItems] = useState(7);
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const createMixDesign = useCreateMixDesign();
  const { data, isLoading, isSuccess, refetch } = useGetMixDesign();
  const deleteMixDesign = useDeleteMixDesign();
  const [editingMixDesign, setEditingMixDesign] = useState("");
  const updateSpecifications = useUpdateMixDesign(editingMixDesign.id);
  const { data: clientData, isSuccess: isSuccessClient } = useGetClients();
  const [clientOptions, setClientOptions] = useState([]);
  const { data: specificationsData, isSuccess: isSuccessSpecifications } =
    useGetSpecifications();
  const [specificationsOptions, setSpecificationsOptions] = useState([]);

  const [
    granulometricBandCodesSpecificationOptions,
    setGranulometricBandCodesSpecificationOptions,
  ] = useState([]);

  const defaultValues = {
    especificacion: "",
    codigo_clasificacion: "",
    json: "",
    proyecto: "",
    pendiente_completar_info: true,
    estabilidad: "",
    flujo: "",
    vacios_aire: "",
    vacios_agregado: "",
    vacios_asfalto: "",
    relacion_llenante: "",
    relacion_estabilidad: "",
    espesor_promedio: "",
    gravedad_especifica_agregados: "",
    gravedad_especifica_asfalto: "",
    formula_trabajo_1: "",
    formula_trabajo_2: "",
    formula_trabajo_3: "",
    formula_trabajo_4: "",
    formula_trabajo_5: "",
    formula_trabajo_6: "",
    formula_trabajo_7: "",
    formula_trabajo_8: "",
    formula_trabajo_9: "",
    formula_trabajo_10: "",
    valor_max_1: "",
    valor_max_2: "",
    valor_max_3: "",
    valor_max_4: "",
    valor_max_5: "",
    valor_max_6: "",
    valor_max_7: "",
    valor_max_8: "",
    valor_max_9: "",
    valor_max_10: "",
    valor_min_1: "",
    valor_min_2: "",
    valor_min_3: "",
    valor_min_4: "",
    valor_min_5: "",
    valor_min_6: "",
    valor_min_7: "",
    valor_min_8: "",
    valor_min_9: "",
    valor_min_10: "",
  };

  const { control, watch, setValue, reset, formState } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const especificacion = watch("especificacion");

  const {
    data: granulometricBandCodesSpecificationData,
    isSuccess: isSuccessGranulometricBandCodesSpecification,
  } = useGetGranulometricBandCodesSpecification(especificacion);

  useEffect(() => {
    if (isSuccessGranulometricBandCodesSpecification) {
      setGranulometricBandCodesSpecificationOptions(
        granulometricBandCodesSpecificationData?.data.map((ele) => ({
          label: ele,
          value: ele,
        }))
      );
    }
  }, [
    isSuccessGranulometricBandCodesSpecification,
    granulometricBandCodesSpecificationData,
  ]);

  useEffect(() => {
    if (isSuccessClient) {
      setClientOptions(
        clientData?.data.map((ele) => ({
          label: ele.proyecto,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessClient]);

  useEffect(() => {
    if (isSuccessSpecifications) {
      setSpecificationsOptions(
        specificationsData?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.nombre,
        }))
      );
    }
  }, [isSuccessSpecifications]);

  const handleUpdate = (id) => {
    setOpenModal(true);
    setIsEditing(true);
    const mixDesignToUpdate = data && data.data.find((spec) => spec.id === id);
    console.log(mixDesignToUpdate);
    if (mixDesignToUpdate) {
      setEditingMixDesign(mixDesignToUpdate);
      if (mixDesignToUpdate.json) {
        reset({
          especificacion: mixDesignToUpdate.especificacion,
          codigo_clasificacion: mixDesignToUpdate.codigo_clasificacion,
          proyecto: mixDesignToUpdate.proyecto,
          estabilidad: mixDesignToUpdate.json.estabilidad,
          flujo: mixDesignToUpdate.json.flujo,
          vacios_aire: mixDesignToUpdate.json.vacios_aire,
          vacios_agregado: mixDesignToUpdate.json.vacios_agregado,
          vacios_asfalto: mixDesignToUpdate.json.vacios_asfalto,
          relacion_llenante: mixDesignToUpdate.json.relacion_llenante,
          relacion_estabilidad: mixDesignToUpdate.json.relacion_estabilidad,
          espesor_promedio: mixDesignToUpdate.json.espesor_promedio,
          gravedad_especifica_agregados:
            mixDesignToUpdate.json.gravedad_especifica_agregados,
          gravedad_especifica_asfalto:
            mixDesignToUpdate.json.gravedad_especifica_asfalto,
          formula_trabajo_1: mixDesignToUpdate.json.formula_trabajo_1,
          formula_trabajo_2: mixDesignToUpdate.json.formula_trabajo_2,
          formula_trabajo_3: mixDesignToUpdate.json.formula_trabajo_3,
          formula_trabajo_4: mixDesignToUpdate.json.formula_trabajo_4,
          formula_trabajo_5: mixDesignToUpdate.json.formula_trabajo_5,
          formula_trabajo_6: mixDesignToUpdate.json.formula_trabajo_6,
          formula_trabajo_7: mixDesignToUpdate.json.formula_trabajo_7,
          formula_trabajo_8: mixDesignToUpdate.json.formula_trabajo_8,
          formula_trabajo_9: mixDesignToUpdate.json.formula_trabajo_9,
          formula_trabajo_10: mixDesignToUpdate.json.formula_trabajo_10,
          valor_max_1: mixDesignToUpdate.json.valor_max_1,
          valor_max_2: mixDesignToUpdate.json.valor_max_2,
          valor_max_3: mixDesignToUpdate.json.valor_max_3,
          valor_max_4: mixDesignToUpdate.json.valor_max_4,
          valor_max_5: mixDesignToUpdate.json.valor_max_5,
          valor_max_6: mixDesignToUpdate.json.valor_max_6,
          valor_max_7: mixDesignToUpdate.json.valor_max_7,
          valor_max_8: mixDesignToUpdate.json.valor_max_8,
          valor_max_9: mixDesignToUpdate.json.valor_max_9,
          valor_max_10: mixDesignToUpdate.json.valor_max_10,
          valor_min_1: mixDesignToUpdate.json.valor_min_1,
          valor_min_2: mixDesignToUpdate.json.valor_min_2,
          valor_min_3: mixDesignToUpdate.json.valor_min_3,
          valor_min_4: mixDesignToUpdate.json.valor_min_4,
          valor_min_5: mixDesignToUpdate.json.valor_min_5,
          valor_min_6: mixDesignToUpdate.json.valor_min_6,
          valor_min_7: mixDesignToUpdate.json.valor_min_7,
          valor_min_8: mixDesignToUpdate.json.valor_min_8,
          valor_min_9: mixDesignToUpdate.json.valor_min_9,
          valor_min_10: mixDesignToUpdate.json.valor_min_10,
        });
      } else {
        reset({
          especificacion: mixDesignToUpdate.especificacion,
          codigo_clasificacion: mixDesignToUpdate.codigo_clasificacion,
          proyecto: mixDesignToUpdate.proyecto,
        });
      }
    } else {
      reset(defaultValues);
    }
  };

  const handleDelete = (id) => {
    deleteMixDesign.reset();
    deleteMixDesign.mutate(id, {
      onSuccess: () => {
        toast.success(`Diseño eliminado con éxito`);
        refetch();
      },
    });
  };

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };

  const handleCreate = () => {
    setOpenModal(true);
    setIsEditing(false);
    setGranulometricBandCodesSpecificationOptions([]);
    reset(defaultValues);
  };

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      const dataTable = data?.data.map((ele, i) => ({
        ...ele,
        proyecto: clientData?.data.find((elem) => elem.id === ele.proyecto)
          ?.proyecto,
        acciones: [
          {
            key: `1${i}`,
            tooltip: ele.archivo ? "Ver documento" : "No hay documento",
            icon: ele.archivo ? file : fileDisable,
            onClick: () => openPDFWindow(ele.archivo),
            disabled: !ele.archivo,
          },
          {
            key: `1${i}`,
            tooltip: "Editar",
            icon: editIcon,
            onClick: () => handleUpdate(ele.id),
            disabled: false,
          },
          {
            key: `1${i}`,
            tooltip: "Eliminar",
            icon: deleteIcon,
            onClick: () => handleDelete(ele.id),
            disabled: false,
          },
        ],
      }));

      setDataTable(dataTable);
      setTotalItems(dataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess]);

  const onSubmit = (payload) => {
    if (isEditing && editingMixDesign) {
      updateSpecifications.mutate(
        {
          ...payload,
        },
        {
          onSuccess: () => {
            toast.success("Diseño actualizado con éxito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    } else {
      createMixDesign.mutate(payload, {
        onSuccess: () => {
          toast.success("Diseño creado con éxito");
          setOpenModal(false);
          reset();
          refetch();
        },
      });
    }
  };

  const openPDFWindow = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Especificación",
      dataIndex: "especificacion",
      key: "especificacion",
    },
    {
      title: "Código de mezcla",
      dataIndex: "codigo_clasificacion",
      key: "codigo_clasificacion",
    },
    {
      title: "Proyecto",
      dataIndex: "proyecto",
      key: "proyecto",
    },
    {
      title: "Pendiente completar",
      dataIndex: "pendiente_completar_info",
      key: "pendiente_completar_info",
      render: (text, record) => (
        <span>{record.pendiente_completar_info ? "Sí" : "No"}</span>
      ),
    },

    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <Container>
      <ModalMixDesign
        openModal={openModal}
        handleClose={handleClose}
        onSubmit={onSubmit}
        formState={formState}
        control={control}
        watch={watch}
        setValue={setValue}
        isLoading={createMixDesign.isLoading}
        isEditing={isEditing}
        clientOptions={clientOptions}
        specificationsOptions={specificationsOptions}
        granulometricBandCodesSpecificationOptions={
          granulometricBandCodesSpecificationOptions
        }
      />
      <Flex justify="space-between">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
          mb="40px"
        >
          Diseños de mezclas asfalticas
        </Text>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={handleCreate}
        >
          Crear diseño
        </Button>
      </Flex>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
