import React, { useEffect, useState } from "react";
import useGetSpecifications from "./hooks/useGetSpecifications";
import useDeleteSpecifications from "./hooks/useDeleteSpecifications";
import useCreateSpecifications from "./hooks/useCreateSpecifications";
import useUpdateSpecifications from "./hooks/useUpdateSpecifications";
import { Container, Flex, Text } from "../../styles/common";
import Table from "../../components/Table";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import Skeleton from "../../components/Skeleton";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import toast from "react-hot-toast";
import { Tooltip } from "antd";
import { useForm } from "react-hook-form";
import { ModalSpecifications } from "./components/ModalSpecifications";

export const Specifications = () => {
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [totalItems, setTotalItems] = useState(7);
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const createSpecifications = useCreateSpecifications();
  const { data, isLoading, isSuccess, refetch } = useGetSpecifications();
  const deleteSpecifications = useDeleteSpecifications();
  const [editingSpecifications, setEditingSpecifications] = useState("");
  const updateSpecifications = useUpdateSpecifications(
    editingSpecifications.id
  );

  const defaultValues = {
    nombre: "",
    descripcion: "",
  };

  const { control, watch, reset, formState } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const handleUpdate = (id) => {
    setOpenModal(true);
    setIsEditing(true);
    const specificationsToUpdate =
      data && data.data.find((spec) => spec.id === id);
    if (specificationsToUpdate) {
      setEditingSpecifications(specificationsToUpdate);
      reset({
        nombre: specificationsToUpdate.nombre,
        descripcion: specificationsToUpdate.descripcion,
      });
    } else {
      reset(defaultValues);
    }
  };

  const handleDelete = (id) => {
    deleteSpecifications.reset();
    deleteSpecifications.mutate(id, {
      onSuccess: () => {
        toast.success(`Especificación eliminada con éxito`);
        refetch();
      },
    });
  };

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };

  const handleCreate = () => {
    setOpenModal(true);
    setIsEditing(false);
    reset(defaultValues);
  };

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      const dataTable = data?.data.map((ele, i) => ({
        ...ele,
        acciones: [
          {
            key: `1${i}`,
            tooltip: "Editar",
            icon: editIcon,
            onClick: () => handleUpdate(ele.id),
            disabled: false,
          },
          {
            key: `1${i}`,
            tooltip: "Eliminar",
            icon: deleteIcon,
            onClick: () => handleDelete(ele.id),
            disabled: false,
          },
        ],
      }));

      setDataTable(dataTable);
      setTotalItems(dataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess]);

  const onSubmit = (payload) => {
    if (isEditing && editingSpecifications) {
      updateSpecifications.mutate(
        {
          ...payload,
        },
        {
          onSuccess: () => {
            toast.success("Especificación actualizada con éxito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    } else {
      createSpecifications.mutate(payload, {
        onSuccess: () => {
          toast.success("Especificación creada con éxito");
          setOpenModal(false);
          reset();
          refetch();
        },
      });
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <Container>
      <ModalSpecifications
        openModal={openModal}
        handleClose={handleClose}
        onSubmit={onSubmit}
        formState={formState}
        control={control}
        watch={watch}
        isLoading={createSpecifications.isLoading}
        isEditing={isEditing}
      />
      <Flex justify="space-between">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
          mb="40px"
        >
          Especificaciones
        </Text>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={handleCreate}
        >
          Crear especificación
        </Button>
      </Flex>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
