import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";

export const SignaturePad = ({ onSave }) => {
  const sigCanvas = useRef({});

  const clear = () => {
    sigCanvas.current.clear();
  };

  const save = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    onSave(dataURL);
  };

  return (
    <div>
      <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{
          width: 250,
          height: 100,
        }}
        backgroundColor="#f0f0f0"
        penColor="#000"
      />
      <div style={{ marginTop: "10px" }}>
        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          hover={theme.colors.light3}
          onClick={clear}
        >
          Limpiar
        </Button>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={save}
        >
          Guardar firma
        </Button>
      </div>
    </div>
  );
};
