import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetTest = (id, isSaved) => {
  const data = useQuery(
    ["getTest", id, isSaved],
    () => api.get(`/ensayo/equipos_campos_ensayo/?ensayo_realizar_id=${id}`),
    { refetchOnWindowFocus: false, enabled: isSaved }
  );

  return data;
};

export default useGetTest;
