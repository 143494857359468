/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useGetEquipment from "../../hooks/useGetEquipment";
import { generateNA8Lines } from "../../../../utilities/helpers";
import { Flex, Text } from "../../../../styles/common";
import { Select } from "../../../../components/Select";
import { Input } from "../../../../components/Input";
import { TextArea } from "../../../../components/TextArea";
import { DatePicker } from "antd";
import Table from "../../../../components/Table";
import { theme } from "../../../../styles/theme";
import deleteIcon from "../../../../assets/icons/delete.svg";
import { Button } from "../../../../components/Button";
import useSaveTest from "../../hooks/useSaveTest";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import useSaveAndFinishTest from "../../hooks/useSaveAndFinishTest";
import useGetTest from "../../hooks/useGetTest";
import useGetResultToNA8 from "../../hooks/useGetResultToNA8";
import moment from "moment";
import { getUser } from "../../../../utilities/helpers";

export const NA8 = ({
  testName,
  amount,
  specification,
  id,
  status,
  isSaved,
}) => {
  const navigate = useNavigate();
  const save = useSaveTest();
  const saveAndFinish = useSaveAndFinishTest();
  const { data, isSuccess } = useGetEquipment();
  const [isSavedTest, setIsSavedTest] = useState(false);
  const { data: testData, isSuccess: isSuccessTest } = useGetTest(
    id,
    isSavedTest
  );
  const { data: resultToNA8Data, isSuccess: isSuccessResultToNA8 } =
    useGetResultToNA8(id);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentsName, setEquipmentsNames] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [resultToNA8, setResultToNA8] = useState(null);

  useEffect(() => {
    if (isSuccessResultToNA8 && resultToNA8Data?.data.length > 0) {
      const result =
        resultToNA8Data.data[0]?.calculos_NA1?.campos_ensayo[0]
          ?.masa_final_mezcla;
      setResultToNA8(result);
    }
  }, [isSuccessResultToNA8, resultToNA8Data]);

  useEffect(() => {
    if (resultToNA8 !== null && amount > 0) {
      setDataTable(
        generateNA8Lines(amount).map((item, index) => ({
          ...item,
          masa_inicial_antes_lavar: resultToNA8,
          especimen: index + 1,
        }))
      );
    }
  }, [resultToNA8, amount]);

  useEffect(() => {
    if (isSaved === "true") {
      setIsSavedTest(true);
    }
  }, [isSaved]);

  const finishedStatus = ["ER"];

  useEffect(() => {
    const finished = finishedStatus.includes(status);
    setIsFinished(finished);
  }, [status]);

  useEffect(() => {
    if (isSuccessTest && testData) {
      setDataTable(
        testData.data.campos_ensayo.map((ele, index) => ({
          key: ele.key,
          especimen: index + 1,
          fecha_ensayo: ele.fecha_ensayo ? moment(ele.fecha_ensayo) : "",
          masa_inicial_antes_lavar: ele.masa_inicial_antes_lavar,
          masa_inicial_despues_lavar: ele.masa_inicial_despues_lavar,
          masa_retenida_1: ele.masa_retenida_1,
          masa_retenida_2: ele.masa_retenida_2,
          masa_retenida_3: ele.masa_retenida_3,
          masa_retenida_4: ele.masa_retenida_4,
          masa_retenida_5: ele.masa_retenida_5,
          masa_retenida_6: ele.masa_retenida_6,
          masa_retenida_7: ele.masa_retenida_7,
          masa_retenida_8: ele.masa_retenida_8,
          masa_retenida_9: ele.masa_retenida_9,
          masa_retenida_10: ele.masa_retenida_10,
          fondo: ele.fondo,
          temperatura: ele.temperatura,
          humedad: ele.humedad,
          observaciones: ele.observaciones,
          id: 0,
        }))
      );
      setEquipments(testData?.data.equipos_campos_ensayo);

      const equipmentsNames = [];
      testData?.data.equipos_campos_ensayo.forEach((numero) => {
        equipmentOptions.forEach((objeto) => {
          if (objeto.value === numero) {
            equipmentsNames.push(objeto.label);
          }
        });
      });

      setEquipmentsNames(equipmentsNames);
    }
  }, [isSuccessTest, testData, equipmentOptions]);

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      setEquipmentOptions(
        data?.data
          .filter((elem) => elem.linea_trabajo === "Línea de asfaltos")
          .map((ele) => ({
            value: ele.id,
            label: `${ele.nombre} (${ele.codigo})`,
          }))
      );
    }
  }, [isSuccess, data]);

  const handleDate = (event, key) => {
    const newDataTable = [...dataTable];

    newDataTable[key]["fecha_ensayo"] = event;
    setDataTable(newDataTable);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event, key) => {
    const { value, id } = event.target;
    const newDataTable = [...dataTable];

    if (id !== "observaciones") {
      newDataTable[key][id] = Number(value);
    }

    newDataTable[key][id] = value;

    setDataTable(newDataTable);
    setForceUpdate(!forceUpdate);
  };

  const columns = [
    {
      title: "Espécimen",
      dataIndex: "especimen",
      key: "especimen",
      align: "center",
    },
    {
      title: "Fecha de ensayo",
      dataIndex: "fecha_ensayo",
      key: "fecha_ensayo",
      align: "center",
      render: (value, record) => (
        <DatePicker
          placeholder="Selecciona una fecha"
          value={value}
          onChange={(event) => handleDate(event, record.key)}
          style={{ width: "120px", height: "40px" }}
          disabled={isFinished}
        />
      ),
    },
    {
      title:
        "Masa inicial antes de lavar [g] (Este valor se toma del ensayo de extracción)",
      dataIndex: "masa_inicial_antes_lavar",
      key: "masa_inicial_antes_lavar",
      align: "center",
      render: (value, record) => (
        <Input
          id="masa_inicial_antes_lavar"
          type="number"
          min={0}
          value={value}
          width={"150px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={true}
        />
      ),
    },
    {
      title: "Masa inicial despues de lavar [g]",
      dataIndex: "masa_inicial_despues_lavar",
      key: "masa_inicial_despues_lavar",
      align: "center",
      render: (value, record) => (
        <Input
          id="masa_inicial_despues_lavar"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "Masa retenida en tamices [g]",
      children: [
        {
          title: '(1 1/2") (37,5 mm)',
          dataIndex: "masa_retenida_1",
          key: "masa_retenida_1",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_1"
              type="number"
              min={0}
              value={value}
              width={"120px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(1") (25 mm)',
          dataIndex: "masa_retenida_2",
          key: "masa_retenida_2",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_2"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(3/4") (19 mm)',
          dataIndex: "masa_retenida_3",
          key: "masa_retenida_3",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_3"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(1/2") (12,5 mm)',
          dataIndex: "masa_retenida_4",
          key: "masa_retenida_4",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_4"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(3/8") (9,5 mm)',
          dataIndex: "masa_retenida_5",
          key: "masa_retenida_5",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_5"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "(N°4) (4,75 mm)",
          dataIndex: "masa_retenida_6",
          key: "masa_retenida_6",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_6"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(N°10") (2 mm)',
          dataIndex: "masa_retenida_7",
          key: "masa_retenida_7",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_7"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(N°40") (0,425 mm)',
          dataIndex: "masa_retenida_8",
          key: "masa_retenida_8",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_8"
              type="number"
              min={0}
              value={value}
              width={"130px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(N°80") (0,18 mm)',
          dataIndex: "masa_retenida_9",
          key: "masa_retenida_9",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_9"
              type="number"
              min={0}
              value={value}
              width={"130px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: '(N°200") (0,075 mm)',
          dataIndex: "masa_retenida_10",
          key: "masa_retenida_10",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_retenida_10"
              type="number"
              min={0}
              value={value}
              width={"140px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Fondo",
          dataIndex: "fondo",
          key: "fondo",
          align: "center",
          render: (value, record) => (
            <Input
              id="fondo"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title: "Condiciones ambientales del espécimen en el momento del ensayo",
      children: [
        {
          title: "Temperatura (°C)",
          dataIndex: "temperatura",
          key: "temperatura",
          align: "center",
          render: (value, record) => (
            <Input
              id="temperatura"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Humedad relativa(%)",
          dataIndex: "humedad",
          key: "humedad",
          align: "center",
          render: (value, record) => (
            <Input
              id="humedad"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title:
        "Observaciones (Indicar si hay defectos en el refrentado o en el espécimen)",
      dataIndex: "observaciones",
      key: "observaciones",
      align: "center",
      render: (value, record) => (
        <TextArea
          id="observaciones"
          value={value}
          width={"300px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
  ];

  const handleEquipment = (event) => {
    setEquipments([...equipments, event]);
    setForceUpdate(!forceUpdate);

    if (event !== undefined) {
      const newEquipmentName = equipmentOptions.find(
        (ele) => ele.value === event
      )?.label;

      setEquipmentsNames([...equipmentsName, newEquipmentName]);
      setForceUpdate(!forceUpdate);
    }
  };

  const handleDeleteEquipment = (indx) => {
    const newEquipmentsNames = equipmentsName.filter(
      (ele, index) => index !== indx
    );
    const newEquipments = equipments.filter((ele, index) => index !== indx);
    setEquipments(newEquipments);
    setEquipmentsNames(newEquipmentsNames);
    setForceUpdate(!forceUpdate);
  };

  const addTestLine = () => {
    setDataTable([
      ...dataTable,
      {
        key: dataTable.length,
        especimen: dataTable.length + 1,
        fecha_ensayo: "",
        masa_inicial_antes_lavar: resultToNA8,
        masa_inicial_despues_lavar: null,
        masa_retenida_1: null,
        masa_retenida_2: null,
        masa_retenida_3: null,
        masa_retenida_4: null,
        masa_retenida_5: null,
        masa_retenida_6: null,
        masa_retenida_7: null,
        masa_retenida_8: null,
        masa_retenida_9: null,
        masa_retenida_10: null,
        fondo: null,
        temperatura: null,
        humedad: null,
        observaciones: "",
        id: 0,
      },
    ]);
  };

  const deleteTestLine = () => {
    const newData = dataTable.slice(0, -1);
    setDataTable(newData);
    setForceUpdate(!forceUpdate);
  };

  const handleSave = () => {
    save.reset();
    save.mutate(
      {
        id_ensayo_realizar: Number(id),
        data: {
          equipos_campos_ensayo: [...equipments],
          campos_ensayo: [...dataTable],
          ensayo_realizar: Number(id),
        },
      },
      {
        onSuccess: () => {
          toast.success("Ensayo guardado con éxito");
          navigate("/test");
        },
      }
    );
  };

  const handleSaveAndFinish = () => {
    saveAndFinish.reset();
    saveAndFinish.mutate(
      {
        id_ensayo_realizar: Number(id),
        data: {
          equipos_campos_ensayo: [...equipments],
          campos_ensayo: [...dataTable],
          ensayo_realizar: Number(id),
        },
      },
      {
        onSuccess: () => {
          toast.success("Ensayo guardado y finalizado con éxito");
          navigate("/test");
        },
      }
    );
  };

  return (
    <>
      <Flex direction="column" justify="center" align="center" m="10px 0 0 0">
        <Text weight={theme.fonts.weight.bold}>{testName}</Text>
        <Text weight={theme.fonts.weight.bold}>{specification}</Text>
      </Flex>
      <Flex m="50px 0 0 0" justify="space-between" align="center">
        <Select
          allowClear
          label={"Equipos empleados"}
          options={equipmentOptions}
          placeholder={"Selecciona los equipos empleados"}
          onChange={handleEquipment}
          style={{ marginBottom: "10px" }}
          disabled={isFinished}
        />
      </Flex>
      {equipmentsName.length > 0 &&
        equipmentsName.map((ele, indx) => (
          <Flex width="400px" justify={"space-between"} m="10px 0 0 0">
            <Text>{ele}</Text>
            {!isFinished && (
              <img
                alt="delete"
                src={deleteIcon}
                height={"20px"}
                onClick={() => handleDeleteEquipment(indx)}
                style={{ cursor: "pointer" }}
              />
            )}
          </Flex>
        ))}
      <Flex m="40px 0 0 0" width="100%">
        <Table data={dataTable} columns={columns} pagination={false} />
      </Flex>
      {!isFinished && (
        <>
          <Flex gap="24px" m="20px 0 0 0">
            <Button
              background={theme.colors.orange}
              hover={theme.colors.orangeHover}
              onClick={addTestLine}
            >
              Añadir línea
            </Button>
            <Button
              background={theme.colors.white}
              color={theme.colors.dark}
              hover={
                dataTable.length === Number(amount)
                  ? theme.colors.gray
                  : theme.colors.light3
              }
              onClick={deleteTestLine}
              disabled={dataTable.length === Number(amount)}
            >
              Eliminar línea
            </Button>
          </Flex>
          {getUser().permissions?.includes(15) && (
            <Flex justify="right" m="80px 0 0 0" gap="16px">
              <Button
                background={theme.colors.white}
                color={theme.colors.orange}
                hover={theme.colors.light3}
                border={theme.colors.orange}
                onClick={handleSave}
                isLoading={save.isLoading}
              >
                Guardar
              </Button>
              <Button
                background={theme.colors.orange}
                hover={theme.colors.orangeHover}
                onClick={handleSaveAndFinish}
                isLoading={saveAndFinish.isLoading}
              >
                Guardar y finalizar
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  );
};
