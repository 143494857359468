import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetEquipment = () => {
  const data = useQuery(["useGetEquipment"], () => api.get(`/ensayo/equipos`));

  return data;
};

export default useGetEquipment;
