import React from "react";
import Modal from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Flex } from "../../../styles/common";
import { Controller } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";

export const ModalEquipment = ({
  openModal,
  handleClose,
  onSubmit,
  formState,
  control,
  watch,
  isLoading,
  isEditing,
  workLineOptions,
}) => {
  const { isDirty, isValid, errors } = formState;

  return (
    <Modal
      align="center"
      isOpen={openModal}
      onClose={handleClose}
      padding={36}
      width={"min-content"}
    >
      <Modal.Header
        padding="0 0 16px 0"
        title={isEditing ? "Actualizar equipo" : "Crear equipo"}
        size={theme.fonts.size.h5}
      />
      <Modal.Body margin="16px 0 24px 0" minHeight={600}>
        <Flex direction="column" justify="center" gap="18px">
          <Controller
            name="codigo"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="codigo"
                placeholder={"Código"}
                error={!!errors.codigo}
                helper={
                  errors?.codigo && errors?.codigo?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="nombre"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="nombre"
                placeholder={"Nombre"}
                error={!!errors.nombre}
                helper={
                  errors?.nombre && errors?.nombre?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="linea_trabajo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="linea_trabajo"
                error={!!errors.linea_trabajo}
                helper={
                  errors?.linea_trabajo &&
                  errors?.linea_trabajo?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Línea de trabajo
                </option>
                {workLineOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="observacion"
            control={control}
            rules={{ required: true, maxLength: 200 }}
            render={({ field }) => (
              <Input
                id="observacion"
                placeholder={"Observación"}
                error={!!errors.observacion}
                helper={
                  errors?.observacion &&
                  errors?.observacion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
        </Flex>
        <Flex m="30px 0 0 0" align="center" justify="center" gap={"24px"}>
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={
              !isDirty || !isValid
                ? theme.colors.gray
                : theme.colors.orangeHover
            }
            disabled={!isDirty || !isValid}
            isLoading={isLoading}
            onClick={() => {
              onSubmit({
                codigo: watch("codigo"),
                nombre: watch("nombre"),
                linea_trabajo: watch("linea_trabajo"),
                observacion: watch("observacion"),
              });
            }}
          >
            {isEditing ? "Actualizar" : "Guardar"}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
