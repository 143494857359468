import Joi from "joi";

export const passwordPattern = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.~!@#$%^&*+-]).{8,}$/
);

export const validateGeneralInfo = Joi.object({
  linea_trabajo: Joi.number().min(1).max(100).required(),
  transporte: Joi.number().min(1).max(100).required(),
  cliente_proyecto: Joi.number().min(1).required(),
  fecha_ingreso_muestras: Joi.object().required(),
});

export const validateGranularSamplesInfo = Joi.object({
  proveedor: Joi.number().min(1).max(200).required(),
  especificacion: Joi.number().min(1).max(200).required(),
  especimen: Joi.number().min(1).max(200).required(),
  codigo_laboratorio: Joi.string().min(1).max(50).allow("", null),
  fecha_toma: Joi.object().allow("", null),
  hora_toma: Joi.object().allow("", null),
  codigo_mezcla: Joi.string().min(1).max(50).allow("", null),
  descripcion_mezcla: Joi.string().min(1).max(50).allow("", null),
  resistencia_nominal: Joi.number().min(1).allow("", null),
  slum: Joi.number().min(1).allow("", null),
  temperatura: Joi.number().min(1).allow("", null),
  numero_remision: Joi.string().min(1).max(200).required(),
  georeferenciacion: Joi.string().min(1).max(200).required(),
  descripcion_georeferenciacion: Joi.string().min(1).max(200).required(),
  cantidad_especimen: Joi.number().min(1).max(200).required(),
  locacion: Joi.string().min(1).max(200).required(),
  observacion: Joi.string().min(1).max(500).required(),
  clasificacion: Joi.string().min(1).max(50).allow("", null),
  abscisado_inicial: Joi.string().min(1).max(200).allow("", null),
  abscisado_final: Joi.string().min(1).max(200).allow("", null),
  usar_formula_trabajo: Joi.boolean().allow(true, false).default(false),
  ensayos_realizar: Joi.array()
    .items(
      Joi.object({
        cantidad_fallo: Joi.number().min(0).required(),
        testigo_fallo: Joi.number().min(0).required(),
        edad_fallo: Joi.number().min(0).required(),
        dia_hora: Joi.string().allow("", null),
        observacion: Joi.string().required(),
        ensayo: Joi.number().required(),
        normativa: Joi.string().allow(""),
        diagrama_NA9: Joi.string().allow("", null),
        id: Joi.number().allow("", null),
      })
    )
    .min(1)
    .required(),
  orden_servicio: Joi.number().allow("", null),
  id: Joi.number().allow("", null),
});

export const validateConcreteSamplesInfo = Joi.object({
  proveedor: Joi.number().min(1).max(200).required(),
  especificacion: Joi.number().min(1).max(200).required(),
  especimen: Joi.number().min(1).max(200).required(),
  codigo_laboratorio: Joi.string().min(1).max(50).allow("", null),
  fecha_toma: Joi.object().required(),
  hora_toma: Joi.object().required(),
  codigo_mezcla: Joi.string().min(1).max(50).required(),
  descripcion_mezcla: Joi.string().min(1).max(50).required(),
  resistencia_nominal: Joi.number().min(1).required(),
  slum: Joi.number().min(1).required(),
  temperatura: Joi.number().min(1).required(),
  numero_remision: Joi.string().min(1).max(200).required(),
  georeferenciacion: Joi.string().min(1).max(200).required(),
  descripcion_georeferenciacion: Joi.string().min(1).max(200).required(),
  cantidad_especimen: Joi.number().min(1).max(200).required(),
  locacion: Joi.string().min(1).max(200).required(),
  observacion: Joi.string().min(1).max(500).required(),
  clasificacion: Joi.string().min(1).max(50).allow("", null),
  abscisado_inicial: Joi.string().min(1).max(200).allow("", null),
  abscisado_final: Joi.string().min(1).max(200).allow("", null),
  usar_formula_trabajo: Joi.boolean().allow(true, false).default(false),
  ensayos_realizar: Joi.array()
    .items(
      Joi.object({
        cantidad_fallo: Joi.number().min(0).required(),
        testigo_fallo: Joi.number().min(0).required(),
        edad_fallo: Joi.number().min(0).required(),
        dia_hora: Joi.string().allow("", null),
        observacion: Joi.string().allow("", null),
        ensayo: Joi.number().required(),
        normativa: Joi.string().required(),
        diagrama_NA9: Joi.string().allow("", null),
        id: Joi.number().allow("", null),
      })
    )
    .min(1)
    .required(),
  orden_servicio: Joi.number().allow("", null),
  id: Joi.number().allow("", null),
});

export const validateAsphaltSamplesInfo = Joi.object({
  proveedor: Joi.number().min(1).max(200).required(),
  especificacion: Joi.number().min(1).max(200).required(),
  especimen: Joi.number().min(1).max(200).required(),
  codigo_laboratorio: Joi.string().min(1).max(50).allow("", null),
  fecha_toma: Joi.object().required(),
  hora_toma: Joi.object().required(),
  codigo_mezcla: Joi.string().min(1).max(50).allow("", null),
  descripcion_mezcla: Joi.string().min(1).max(50).allow("", null),
  resistencia_nominal: Joi.number().min(1).allow("", null),
  slum: Joi.number().allow("", null),
  temperatura: Joi.number().min(0).required(),
  numero_remision: Joi.string().min(1).max(200).allow("", null),
  georeferenciacion: Joi.string().min(1).max(200).required(),
  descripcion_georeferenciacion: Joi.string().min(1).max(200).required(),
  cantidad_especimen: Joi.number().min(1).max(200).required(),
  locacion: Joi.string().min(1).max(200).required(),
  observacion: Joi.string().min(1).max(500).required(),
  clasificacion: Joi.string().min(1).max(200).required(),
  abscisado_inicial: Joi.string().min(1).max(200).required(),
  abscisado_final: Joi.string().min(1).max(200).required(),
  usar_formula_trabajo: Joi.boolean().allow(true, false).default(false),
  ensayos_realizar: Joi.array()
    .items(
      Joi.object({
        cantidad_fallo: Joi.number().allow(null),
        testigo_fallo: Joi.number().allow(null),
        edad_fallo: Joi.number().allow(null),
        dia_hora: Joi.string().allow(""),
        observacion: Joi.string().allow(""),
        ensayo: Joi.number().required(),
        normativa: Joi.string().allow(""),
        diagrama_NA9: Joi.string().allow("", null),
        id: Joi.number().allow("", null),
      })
    )
    .min(1)
    .required(),
  orden_servicio: Joi.number().allow("", null),
  id: Joi.number().allow("", null),
});

export const validateTestsConcrete = Joi.object({
  cantidad_fallo: Joi.number().min(0).required(),
  testigo_fallo: Joi.number().min(0).required(),
  edad_fallo: Joi.number().min(0).required(),
  dia_hora: Joi.string().allow("", null),
  observacion: Joi.string().allow("", null),
  ensayo: Joi.number().required(),
  normativa: Joi.string().required(),
  diagrama_NA9: Joi.string().allow("", null),
  id: Joi.number().allow("", null),
});

export const validateArrayTestsConcrete = Joi.array().items(
  validateTestsConcrete
);

export const validateTestsAsphalt = Joi.object({
  cantidad_fallo: Joi.number().allow(null),
  testigo_fallo: Joi.number().allow(null),
  edad_fallo: Joi.number().allow(null),
  dia_hora: Joi.string().allow(""),
  observacion: Joi.string().allow(""),
  ensayo: Joi.number().required(),
  normativa: Joi.string().allow(""),
  diagrama_NA9: Joi.string().allow("", null),
  id: Joi.number().allow("", null),
});

export const validateArrayTestsAsphalt =
  Joi.array().items(validateTestsAsphalt);
