import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetExportDataFilter = (
  start_date,
  end_date,
  status_order,
  id_order
) => {
  const data = useQuery(["useGetExportDataFilter"], () =>
    api.get(
      `/orden_servicio/filtro_excel_orden_servicio/?data={"id_orden":${id_order},"fecha_creacion_inicio":${start_date},"fecha_creacion_fin":${end_date},"estado_orden":${status_order}}`
    )
  );
  return data;
};

export default useGetExportDataFilter;
