import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetResultsAsphalt = (id, isReady) => {
  const data = useQuery(
    ["useGetResultsAsphalt", id, isReady],
    () =>
      api.get(
        `/ensayo/datos-ensayo-informe-asfaltos/?ensayo_realizar_id=${id}`
      ),
    { refetchOnWindowFocus: false, enabled: isReady }
  );

  return data;
};

export default useGetResultsAsphalt;
