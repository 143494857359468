import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetDataBarChart = () => {
  const data = useQuery(["useGetDataBarChart"], () =>
    api.get(`/reporte/reporte_cantidad_OS_por_estados`)
  );

  return data;
};

export default useGetDataBarChart;
