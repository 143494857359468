import React, { useState, useEffect } from "react";
import useGetClients from "../../ServiceOrder/hook/getClients";
import useCreateMixDesign from "./../hooks/useCreateMixDesign";
import { Controller } from "react-hook-form";
import { Select } from "../../../components/Select";
import { useForm } from "react-hook-form";
import { Container, Flex, Text } from "../../../styles/common";
import { Input } from "../../../components/Input";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useGetSpecifications from "../../Specifications/hooks/useGetSpecifications";
import useGetGranulometricBandCodesSpecification from "../hooks/useGetGranulometricBandCodesSpecification";

export const CreationRequetsMixDesign = () => {
  const navigate = useNavigate();
  const createMixDesign = useCreateMixDesign();
  const { data: clientData, isSuccess: isSuccessClient } = useGetClients();
  const [clientOptions, setClientOptions] = useState([]);
  const [fileError, setFileError] = useState("");
  const [saveSignatureImage, setSaveSignatureImage] = useState(null);
  const { data: specificationsData, isSuccess: isSuccessSpecifications } =
    useGetSpecifications();
  const [specificationsOptions, setSpecificationsOptions] = useState([]);

  const [
    granulometricBandCodesSpecificationOptions,
    setGranulometricBandCodesSpecificationOptions,
  ] = useState([]);
  const defaultValues = {
    codigo_clasificacion: "",
    proyecto: "",
    archivo: "",
    pendiente_completar_info: true,
  };

  const { control, watch, setValue, reset, formState } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const { isDirty, isValid, errors } = formState;

  const especificacion = watch("especificacion");

  useEffect(() => {
    setValue("codigo_clasificacion", "");
  }, [especificacion, setValue]);

  const {
    data: granulometricBandCodesSpecificationData,
    isSuccess: isSuccessGranulometricBandCodesSpecification,
  } = useGetGranulometricBandCodesSpecification(especificacion);

  useEffect(() => {
    if (isSuccessGranulometricBandCodesSpecification) {
      setGranulometricBandCodesSpecificationOptions(
        granulometricBandCodesSpecificationData?.data.map((ele) => ({
          label: ele,
          value: ele,
        }))
      );
    }
  }, [
    isSuccessGranulometricBandCodesSpecification,
    granulometricBandCodesSpecificationData,
  ]);

  useEffect(() => {
    if (isSuccessSpecifications) {
      setSpecificationsOptions(
        specificationsData?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.nombre,
        }))
      );
    }
  }, [isSuccessSpecifications]);

  useEffect(() => {
    if (isSuccessClient) {
      setClientOptions(
        clientData?.data.map((ele) => ({
          label: ele.proyecto,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessClient]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        setFileError("Solo se permiten archivos PDF");
        setSaveSignatureImage(null);
      } else {
        setFileError("");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          setSaveSignatureImage(base64String);
        };
      }
    }
  };

  const onSubmit = (payload) => {
    createMixDesign.mutate(payload, {
      onSuccess: () => {
        toast.success("Diseño solicitado con éxito");
        navigate("/home");
      },
    });
  };

  return (
    <>
      <Container>
        <Flex>
          <Text
            size={theme.fonts.size.h4}
            weight={theme.fonts.weight.bold}
            color={theme.colors.dark}
            style={{ textAlign: "left" }}
            mb="40px"
          >
            Solicitud de creación de diseño de mezcla asfaltica
          </Text>
        </Flex>

        <Flex direction="row" gap="18px">
          <Controller
            name="especificacion"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={"Especificación"}
                placeholder={"Especificación"}
                id="especificacion"
                error={!!errors.especificacion}
                helper={
                  errors?.especificacion &&
                  errors?.especificacion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Especificación
                </option>
                {specificationsOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="codigo_clasificacion"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={"Código"}
                id="codigo_clasificacion"
                error={!!errors.codigo_clasificacion}
                helper={
                  errors?.codigo_clasificacion &&
                  errors?.codigo_clasificacion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Código
                </option>
                {granulometricBandCodesSpecificationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="proyecto"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={"Proyecto"}
                id="proyecto"
                error={!!errors.proyecto}
                helper={
                  errors?.proyecto && errors?.proyecto?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Proyecto
                </option>
                {clientOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <div>
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
              lang="es"
            />
            {fileError && <p style={{ color: "red" }}>{fileError}</p>}
          </div>
        </Flex>
        <Flex m="30px 0 0 0" align="center" justify="center" gap={"24px"}>
          <Button
            background={theme.colors.orange}
            hover={
              !isValid || !saveSignatureImage
                ? theme.colors.gray
                : theme.colors.orangeHover
            }
            disabled={!isValid || !saveSignatureImage}
            onClick={() =>
              onSubmit({
                especificacion: watch("especificacion"),
                codigo_clasificacion: watch("codigo_clasificacion"),
                proyecto: watch("proyecto"),
                archivo: saveSignatureImage,
                pendiente_completar_info: true,
              })
            }
          >
            Enviar
          </Button>
        </Flex>
      </Container>
    </>
  );
};
