import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetGranulometricBandCodesSpecification = (especificacion) => {
  const data = useQuery(
    ["useGetGranulometricBandCodesSpecification", especificacion],
    () =>
      api.get(
        `/muestra/codigos_franjas_granulometricas_por_especificacion/?especificacion="${especificacion}"`
      ),
    { enabled: Boolean(especificacion) }
  );

  return data;
};

export default useGetGranulometricBandCodesSpecification;
