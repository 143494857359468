import React, { useEffect, useState } from "react";
import useGetResultsConcrete from "./hooks/useGetResultsConcrete";
import useGetResultsAsphalt from "./hooks/useGetResultsAsphalt";
import { useLocation, useParams } from "react-router-dom";
import { Flex, Text, Container } from "../../styles/common";
import { theme } from "../../styles/theme";
import Table from "../../components/Table";
import { Link } from "../../components/Link";
import { useNavigate } from "react-router";
import arrowBack from "../../assets/icons/arrow-back.svg";
import { getUser } from "../../utilities/helpers";
import { Button } from "../../components/Button";
import toast from "react-hot-toast";
import useSaveApproveTest from "./hooks/useSaveApproveTest";
import Modal from "../../components/Modal";
import { TextArea } from "../../components/TextArea";
import {
  columnsNC1Results,
  columnsNC2Results,
  columnsNC3Results,
  columnsNC4Results,
  columnsNA1Results,
  columnsNA2Results,
  columnsNA3Results,
  columnsNA4Results,
  columnsNA5Results,
  columnsNA7Results,
  columnsNA8Results,
} from "../../utilities/helpers";

import { Line } from "react-chartjs-2";
import "chart.js/auto";

export const Results = () => {
  const { id } = useParams();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const workLine = searchParams.get("workLine");
  const [searchConcrete, setSearchConcrete] = useState(false);
  const [searchAsphalt, setSearchAsphalt] = useState(false);
  const concreteData = useGetResultsConcrete(Number(id), searchConcrete);
  const asphaltData = useGetResultsAsphalt(Number(id), searchAsphalt);
  const apiReportData =
    workLine === "Línea de concretos" ? concreteData : asphaltData;
  const { data, isSuccess } = apiReportData;
  const [dataTable, setDataTable] = useState([]);
  const navigate = useNavigate();
  const [rejectMessage, setRejectMessage] = useState("");
  const [error, setError] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const update = useSaveApproveTest();

  useEffect(() => {
    if (workLine === "Línea de concretos") {
      setSearchConcrete(true);
    } else if (workLine === "Línea de asfaltos") {
      setSearchAsphalt(true);
    }
  }, [workLine]);

  useEffect(() => {
    if (isSuccess && data?.data.campos_ensayo.length > 0) {
      let dataTable = [];
      if (
        workLine === "Línea de asfaltos" &&
        data?.data.campos_ensayo[0].codigo_ensayo === "NA8"
      ) {
        dataTable = data?.data.campos_ensayo.flatMap((ele) => {
          const rows = [{}, {}, {}];
          rows[0]["porcentaje_0"] = "Que pasa";
          rows[1]["porcentaje_0"] = "Retenido";
          rows[2]["porcentaje_0"] = "Retenido acumulado";
          for (let i = 1; i <= 10; i++) {
            rows[0][`porcentaje_${i}`] = ele[`porcentaje_que_pasa_${i}`] + " %";
            rows[1][`porcentaje_${i}`] = ele[`porcentaje_retenido_${i}`] + " %";
            rows[2][`porcentaje_${i}`] =
              ele[`porcentaje_retenido_acumulado_${i}`] + " %";
          }
          rows[0]["porcentaje_fondo"] = ele[`porcentaje_que_pasa_fondo`] + " %";
          rows[1]["porcentaje_fondo"] = ele[`porcentaje_masa_fondo`] + " %";
          rows[2]["porcentaje_fondo"] =
            ele[`porcentaje_fondo_acumulado`] + " %";
          return rows;
        });
      } else {
        dataTable = data?.data.campos_ensayo.map((ele, i) => ({
          ...ele,
        }));
      }

      setDataTable(dataTable);
    } else {
      setDataTable([]);
    }
  }, [isSuccess, data]);

  let columns;

  switch (data?.data.campos_ensayo[0].codigo_ensayo) {
    //Linea de concretos
    case "NC1":
      columns = columnsNC1Results();
      break;
    case "NC2":
      columns = columnsNC2Results();
      break;
    case "NC3":
      columns = columnsNC3Results();
      break;
    case "NC4":
      columns = columnsNC4Results();
      break;
    //Linea de asfaltos
    case "NA1":
      columns = columnsNA1Results();
      break;
    case "NA2":
      columns = columnsNA2Results();
      break;
    case "NA3":
      columns = columnsNA3Results();
      break;
    case "NA4":
      columns = columnsNA4Results();
      break;
    case "NA5":
      columns = columnsNA5Results();
      break;
    case "NA7":
      columns = columnsNA7Results();
      break;
    case "NA8":
      columns = columnsNA8Results();
      break;
    default:
      columns = [];
      break;
  }

  const handleUpdate = (accept, reject) => {
    if (reject && rejectMessage === "") {
      setError(true);
      setForceUpdate(!forceUpdate);
    } else {
      update.reset();
      update.mutate(
        {
          id_usuario: getUser().id,
          id_ensayo_realizar: Number(id),
          aprobar: accept,
          rechazar: reject,
          observacion: rejectMessage,
          json: data?.data,
        },
        {
          onSuccess: () => {
            toast.success(
              `Muestra ${accept ? "Aceptada" : "Rechazada"} con éxito`
            );
            navigate("/test");
          },
        }
      );
    }
  };

  const handleChangeReject = (e) => {
    const { value } = e.target;
    setRejectMessage(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleClose = () => {
    setOpenModal(false);
    setRejectMessage("");
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  const porcentajeQuePasa = data?.data.campos_ensayo[0].porcentajes_que_pasan;
  const limite_superior = data?.data.campos_ensayo[0].limite_superior;
  const limite_inferior = data?.data.campos_ensayo[0].limite_inferior;
  const diametro_particula = data?.data.campos_ensayo[0].diametro_particula;
  const valores_max_formula_trabajo =
    data?.data.campos_ensayo[0].valores_max_formula_trabajo;
  const valores_min_formula_trabajo =
    data?.data.campos_ensayo[0].valores_min_formula_trabajo;
  const usar_formula_trabajo = data?.data.campos_ensayo[0].usar_formula_trabajo;

  const datasets = [
    {
      label: "Porcentaje Que Pasa",
      data: porcentajeQuePasa,
      borderColor: "rgba(0, 0, 0, 1)",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      fill: false,
      tension: 0.1,
    },
    {
      label: "Limite superior",
      data: limite_superior,
      borderColor: "rgba(255, 0, 0, 1)",
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      fill: false,
      tension: 0.1,
    },
    {
      label: "Límite inferior",
      data: limite_inferior,
      borderColor: "rgba(255, 0, 0, 1)",
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      fill: false,
      tension: 0.1,
    },
  ];

  if (usar_formula_trabajo) {
    datasets.push(
      {
        label: "Valores Máximos",
        data: valores_max_formula_trabajo,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: false,
        tension: 0.1,
        borderDash: [10, 5],
      },
      {
        label: "Valores Mínimos",
        data: valores_min_formula_trabajo,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: false,
        tension: 0.1,
        borderDash: [10, 5],
      }
    );
  }

  const dataChart = {
    labels: diametro_particula,
    datasets: datasets,
  };

  const options = {
    scales: {
      x: {
        type: "logarithmic",
        position: "bottom",
        reverse: true,
        title: {
          display: true,
          text: "Diámetro de Partícula (mm)",
        },
        ticks: {
          callback: function (value) {
            return Number(value).toLocaleString();
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "% que pasa",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <>
      <Container>
        <Modal
          align="center"
          isOpen={openModal}
          onClose={handleClose}
          padding={36}
          style={{ width: "100px" }}
        >
          <Modal.Header
            padding="0 0 16px 0"
            title="Rechazar ensayo"
            size={theme.fonts.size.h5}
          />
          <Modal.Body margin="16px 0 24px 0" minHeight={600}>
            <TextArea
              value={rejectMessage}
              label={"Motivo de rechazo*"}
              placeholder={"Ingrese el motivo del rechazo del ensayo"}
              onChange={handleChangeReject}
              width={"100%"}
              error={error}
              helper={error ? "Campo requerido" : ""}
            />
          </Modal.Body>
          <Modal.Footer gap={"16px"} justify="center">
            <Button
              background={theme.colors.white}
              hover={theme.colors.light3}
              color={theme.colors.dark}
              onClick={handleClose}
              style={{ width: "150px" }}
            >
              Cancelar
            </Button>
            <Button
              background={theme.colors.orange}
              hover={theme.colors.orangeHover}
              onClick={() => handleUpdate(false, true)}
            >
              Rechazar
            </Button>
          </Modal.Footer>
        </Modal>
        <Link
          onClick={handleBackPage}
          icon={<img alt="back" src={arrowBack} />}
        >
          Atras
        </Link>
        {data?.data ? (
          <>
            <Text
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              color={theme.colors.dark}
              style={{ textAlign: "center", margin: "15px 0 15px 0" }}
            >
              Resultados del ensayo {data?.data.campos_ensayo[0].nombre_ensayo}{" "}
              de la orden de servicio N°{" "}
              {data?.data.campos_ensayo[0].id_orden_servicio}
            </Text>
            {workLine === "Línea de concretos" && (
              <>
                <div style={{ textAlign: "left", margin: "20px 0 20px 0" }}>
                  <p>
                    <b>SLUMP: </b>
                    {data?.data.campos_ensayo[0].slump}
                    Pulg
                  </p>
                  <p>
                    <b>Resistencia nominal: </b>
                    {data?.data.campos_ensayo[0].resistencia_nominal}
                    Mpa
                  </p>
                  <p>
                    <b>Temperatura toma de muestras: </b>
                    {data?.data.campos_ensayo[0].temperatura_OS}
                    °C
                  </p>
                </div>
              </>
            )}
            {workLine === "Línea de asfaltos" && (
              <>
                {data?.data.campos_ensayo[0].codigo_ensayo === "NA2" && (
                  <>
                    <Text
                      size={theme.fonts.size.h5}
                      weight={theme.fonts.weight.bold}
                      color={theme.colors.dark}
                      style={{ textAlign: "left", margin: "40px 0 35px 0" }}
                    >
                      {data?.data.campos_ensayo[0].mensaje}.
                    </Text>
                  </>
                )}
                {data?.data.campos_ensayo[0].codigo_ensayo === "NA8" && (
                  <>
                    <Text
                      size={theme.fonts.size.h5}
                      weight={theme.fonts.weight.bold}
                      color={theme.colors.dark}
                      style={{ textAlign: "center", margin: "0 0 10px 0" }}
                    >
                      Curva Granulométrica de la mezcla asfáltica
                    </Text>
                    <Text
                      size={theme.fonts.size.h5}
                      weight={theme.fonts.weight.bold}
                      color={theme.colors.dark}
                      style={{ textAlign: "center", margin: "0 0 10px 0" }}
                    >
                      {usar_formula_trabajo
                        ? "Se aplica fórmula de trabajo"
                        : "No se aplica fórmula de trabajo"}
                    </Text>
                    {usar_formula_trabajo &&
                    valores_max_formula_trabajo.length < 0 &&
                    valores_min_formula_trabajo.length < 0 ? (
                      <Text
                        size={theme.fonts.size.h5}
                        weight={theme.fonts.weight.bold}
                        color={theme.colors.red}
                        style={{ textAlign: "center", margin: "0 0 10px 0" }}
                      >
                        Pero el cliente no ha realizado la solicitud del diseño
                        de mezcla asfaltico.
                      </Text>
                    ) : (
                      <Flex
                        width="70%"
                        style={{ alignSelf: "center", margin: "5px 0 30px 0" }}
                      >
                        <Line data={dataChart} options={options} />
                      </Flex>
                    )}
                  </>
                )}
              </>
            )}

            <Flex width="100%">
              <Table columns={columns} data={dataTable} pagination={false} />
            </Flex>

            <Flex justify="center">
              {data?.data.campos_ensayo[0].codigo_estado_ensayo === "ER" && (
                <Flex gap="16px" justify="flex-end" m="40px 0 20px 0">
                  {getUser().permissions?.includes(21) && (
                    <Button
                      background={theme.colors.orange}
                      hover={theme.colors.orangeHover}
                      onClick={() => handleUpdate(true, false)}
                      style={{ width: "150px" }}
                    >
                      Aceptar
                    </Button>
                  )}
                  {getUser().permissions?.includes(22) && (
                    <Button
                      background={theme.colors.white}
                      hover={theme.colors.light3}
                      color={theme.colors.dark}
                      onClick={() => setOpenModal(true)}
                      style={{ width: "150px" }}
                    >
                      Rechazar
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
          </>
        ) : (
          <div>Cargando datos...</div>
        )}
      </Container>
    </>
  );
};
