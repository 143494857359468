/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetSamples from "./hooks/useGetSamples";
import { WrapperLine } from "../ServiceOrder/styles";
import { Col, Row, Tooltip } from "antd";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import arrowBack from "../../assets/icons/arrow-back.svg";
import useUpdate from "./hooks/useUpdateOrder";
import useSendReportEmail from "./hooks/useSendReportEmail";
import Modal from "../../components/Modal";
import { TextArea } from "../../components/TextArea";
import { toast } from "react-hot-toast";
import moment from "moment";
import Table from "../../components/Table";
import {
  columns,
  concreteColumnsActionDetail,
  concreteColumnsDetail,
  asphaltColumnsActionDetail,
  asphaltColumnsDetail,
  getUser,
  dateFormatHour,
} from "../../utilities/helpers";
import { useMediaQuery } from "react-responsive";
import useGetTest from "../ServiceOrder/hook/useGetTests";
import useGetSpecifications from "../Specifications/hooks/useGetSpecifications";
import useGetProviders from "../Providers/hooks/useGetProviders";
import useGetSpecimens from "../Specimens/hooks/useGetSpecimens";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import document from "../../assets/icons/document.svg";
import documentDisabled from "../../assets/icons/document-disabled.svg";

export const ServiceOrderDetail = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const navigate = useNavigate();
  const update = useUpdate();

  const { id } = useParams();
  const samples = useGetSamples(id);
  const { data, isSuccess } = samples;
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const status = searchParams.get("status");
  const workLine = searchParams.get("workLine");
  const [samplesData, setSamplesData] = useState([]);
  const [currentSample, setCurrentSample] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [error, setError] = useState(false);
  const [totalItems, setTotalItems] = useState(3);
  const [pages, setPages] = useState({ current: 1, pageSize: 3 });
  const [dataTable, setDataTable] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const { data: testData } = useGetTest();
  const { data: specificationsData } = useGetSpecifications();
  const { data: providersData } = useGetProviders();
  const { data: specimensData } = useGetSpecimens();
  const sendReportEmail = useSendReportEmail(samplesData[currentSample]?.id);

  useEffect(() => {
    if (isSuccess) {
      setSamplesData(data?.data);
    }
  }, [isSuccess]);

  const handleNext = () => {
    setCurrentSample(currentSample + 1);
    setForceUpdate(!forceUpdate);
  };

  const handleBack = () => {
    setCurrentSample(currentSample - 1);
    setForceUpdate(!forceUpdate);
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  const exportToExcel = () => {
    const headers = [
      //Muestras
      "Fecha de Creación",
      "N° Orden",
      "Cliente Proyecto",
      "Proveedor",
      "Especificación",
      "Número de Remisión",
      "Tipo especimen",
      "Código de muestra",
      "Fecha de Toma de muestra",
      "Hora de Toma de muestra",
      "Código de Mezcla",
      "Descripción de Mezcla",
      "Resistencia Nominal",
      "Slump",
      "Temperatura (°C)",
      "Dirección",
      "Complemento de la dirección",
      "Localización de la muestra",
      "Total Especimenes de la muestra",
      "Observación de la muestra",
      //Ensayos
      "Nombre del Ensayo",
      "Normativa",
      "N° Especimenes del ensayo",
      "N° Testigos del ensayo",
      "Edad de Falla",
      "Unidad de tiempo",
      "Observación del Ensayo",
      "Estado del ensayo",
    ];

    const dataMatrix = samplesData.flatMap((sample) => {
      const addedMuestras = new Set();
      return sample.ensayos_realizar.reduce((rows, ensayo) => {
        if (!addedMuestras.has(sample.codigo_laboratorio)) {
          addedMuestras.add(sample.codigo_laboratorio);
          rows.push([
            //Muestras
            dateFormatHour(sample.fecha_creacion),
            sample.orden_servicio_id,
            sample.cliente_proyecto,
            sample.proveedor_id,
            sample.especificacion_id,
            sample.numero_remision,
            sample.especimen_id,
            sample.codigo_laboratorio,
            sample.fecha_toma,
            sample.hora_toma,
            sample.codigo_mezcla,
            sample.descripcion_mezcla,
            sample.resistencia_nominal,
            sample.slum,
            sample.temperatura,
            sample.georeferenciacion,
            sample.descripcion_georeferenciacion,
            sample.locacion,
            sample.cantidad_especimen,
            sample.observacion,
            //Ensayos
            ensayo.ensayo,
            ensayo.normativa,
            ensayo.cantidad_fallo,
            ensayo.testigo_fallo,
            ensayo.edad_fallo,
            ensayo.dia_hora,
            ensayo.observacion,
            ensayo.descripcion_estado,
          ]);
        } else {
          rows.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ensayo.ensayo,
            ensayo.normativa,
            ensayo.cantidad_fallo,
            ensayo.testigo_fallo,
            ensayo.edad_fallo,
            ensayo.dia_hora,
            ensayo.observacion,
            ensayo.descripcion_estado,
          ]);
        }
        return rows;
      }, []);
    });

    dataMatrix.unshift(headers);

    const worksheet = XLSX.utils.aoa_to_sheet(dataMatrix);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `Orden de Servicio ${samplesData[0].orden_servicio_id}`
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(
      blob,
      `Exporte Orden de Servicio ${samplesData[0].orden_servicio_id}.xlsx`
    );
  };

  const openPDF = () => {
    const byteCharacters = atob(samplesData[currentSample]?.informe);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const handleUpdate = (accept, reject) => {
    if (reject && rejectMessage === "") {
      setError(true);
      setForceUpdate(!forceUpdate);
    } else {
      update.reset();
      update.mutate(
        {
          id_usuario: getUser().id,
          id_orden: Number(id),
          aprobar: accept,
          rechazar: reject,
          observacion: rejectMessage,
          fecha_aceptacion_muestra: accept
            ? moment().format("YYYY-MM-DD")
            : null,
        },
        {
          onSuccess: () => {
            toast.success(
              `Muestra ${accept ? "Aceptada" : "Rechazada"} con éxito`
            );
            navigate("/service-order/list");
          },
        }
      );
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setRejectMessage("");
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setRejectMessage(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleSendReportEmail = () => {
    sendReportEmail.reset();
    sendReportEmail.mutate(
      {
        id_muestra: samplesData[currentSample]?.id,
      },
      {
        onSuccess: () => {
          toast.success("Informe envíado con éxito");
        },
      }
    );
  };

  const handleResult = (id, workLine) => {
    navigate(`/test/results/${id}?workLine=${workLine}`);
  };

  useEffect(() => {
    if (samplesData[currentSample]?.ensayos_realizar.length > 0) {
      const dataTable = samplesData[currentSample]?.ensayos_realizar.map(
        (ele, i) => ({
          key: i,
          ...ele,
          ensayo:
            status === "VoBo_laboratorio"
              ? testData?.data.find((elem) => elem.id === ele.ensayo)?.nombre
              : ele.ensayo,
          acciones: [
            {
              key: `1${i}`,
              tooltip:
                ele.codigo_estado === "FZ"
                  ? "Ver Resultados"
                  : "Estado finalizado requerido",
              icon: ele.codigo_estado === "FZ" ? document : documentDisabled,
              onClick: () => handleResult(ele.ensayo_id, workLine),
              disabled: ele.codigo_estado !== "FZ",
            },
          ],
        })
      );

      setDataTable(dataTable);
      setTotalItems(samplesData[currentSample]?.ensayos_realizar.length);
      setForceUpdate(!forceUpdate);
    } else {
      setDataTable([]);
    }
  }, [samplesData[currentSample]]);

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  return (
    <Container>
      <Modal
        align="center"
        isOpen={openModal}
        onClose={handleClose}
        padding={36}
        style={{ width: "100px" }}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title="Rechazar orden de servicio"
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={600}>
          <TextArea
            value={rejectMessage}
            label={"Motivo de rechazo*"}
            placeholder={"Ingrese el motivo del rechazo de la orden"}
            onChange={handleChange}
            width={"100%"}
            error={error}
            helper={error ? "Campo requerido" : ""}
          />
        </Modal.Body>
        <Modal.Footer gap={"16px"} justify="center">
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
            style={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={() => handleUpdate(false, true)}
          >
            Rechazar
          </Button>
        </Modal.Footer>
      </Modal>

      <Link
        onClick={handleBackPage}
        icon={<img alt="back" src={arrowBack} />}
        style={{ marginBottom: "10px" }}
      >
        Atras
      </Link>
      <Text
        size={theme.fonts.size.h4}
        weight={theme.fonts.weight.bold}
        color={theme.colors.dark}
        style={{ textAlign: "center" }}
      >
        {status === "VoBo_laboratorio" ? (
          <>
            Detalle de la orden de servicio N°{" "}
            {samplesData[currentSample]?.orden_servicio}
          </>
        ) : (
          <>
            Detalle de la orden de servicio N°{" "}
            {samplesData[currentSample]?.orden_servicio_id}
          </>
        )}
      </Text>
      {samplesData.length > 0 ? (
        <Flex direction="column" p="30px 0 0 0">
          <>
            <Flex
              justify="space-between"
              align={isMobile ? "start" : "center"}
              direction={isMobile ? "column" : "row"}
              gap={isMobile ? "20px" : "0px"}
            >
              <Text size={theme.fonts.size.h5} weight={theme.fonts.weight.bold}>
                Código laboratorio:{" "}
                {samplesData[currentSample]?.codigo_laboratorio
                  ? samplesData[currentSample]?.codigo_laboratorio
                  : "Pendiente por ingreso de muestras."}
              </Text>
              <WrapperLine>
                <Text
                  weight={theme.fonts.weight.bold}
                  size={theme.fonts.size.h6}
                  color={theme.colors.white}
                  style={{ textAlign: "start" }}
                >
                  {workLine}
                </Text>
              </WrapperLine>
            </Flex>
            <Text size={theme.fonts.size.h5} weight={theme.fonts.weight.bold}>
              Muestra: {currentSample + 1}
            </Text>
            <Text
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.orange}
              mt="30px"
              mb="20px"
            >
              Ensayos
            </Text>
            <Table
              columns={
                workLine === "Línea de concretos"
                  ? getUser().permissions?.includes(28) &&
                    getUser().permissions?.includes(11)
                    ? concreteColumnsActionDetail
                    : concreteColumnsDetail
                  : workLine === "Línea de asfaltos"
                  ? getUser().permissions?.includes(28) &&
                    getUser().permissions?.includes(11)
                    ? asphaltColumnsActionDetail
                    : asphaltColumnsDetail
                  : columns
              }
              data={dataTable}
              sizerLabels={["Mostrando", "por página"]}
              pagination={
                totalItems > 3
                  ? {
                      ...pages,
                      total: totalItems,
                      showSizeChanger: true,
                      pageSizeOptions: ["3", "6", "18"],
                      locale: {
                        items_per_page: "",
                      },
                    }
                  : false
              }
              onChange={handleChangeTable}
              locale={{
                emptyText: "No hay datos",
              }}
            />
            <Text
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.orange}
              mt="30px"
              mb="20px"
            >
              Información general
            </Text>
            <Row gutter={[0, 20]}>
              {status !== "VoBo_laboratorio" && (
                <Col md={12} xs={24}>
                  <Text>
                    <strong>Cliente:</strong>{" "}
                    {samplesData[currentSample]?.cliente_proyecto}
                  </Text>
                </Col>
              )}
              <Col md={12} xs={24}>
                <Text>
                  <strong>Especificación:</strong>{" "}
                  {status === "VoBo_laboratorio"
                    ? specificationsData?.data.find(
                        (elem) =>
                          elem.id === samplesData[currentSample]?.especificacion
                      )?.nombre
                    : samplesData[currentSample]?.especificacion_id}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Especimen:</strong>{" "}
                  {status === "VoBo_laboratorio"
                    ? specimensData?.data.find(
                        (elem) =>
                          elem.id === samplesData[currentSample]?.especimen
                      )?.nombre
                    : samplesData[currentSample]?.especimen_id}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Proveedor:</strong>{" "}
                  {status === "VoBo_laboratorio"
                    ? providersData?.data.find(
                        (elem) =>
                          elem.id === samplesData[currentSample]?.proveedor
                      )?.nombre
                    : samplesData[currentSample]?.proveedor_id}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Dirección:</strong>{" "}
                  {samplesData[currentSample]?.georeferenciacion}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Complemento dirección:</strong>{" "}
                  {samplesData[currentSample]?.descripcion_georeferenciacion}
                </Text>
              </Col>
            </Row>
            <Text
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.orange}
              mt="30px"
              mb="20px"
            >
              Información de la muestra
            </Text>
            <Row gutter={[0, 20]}>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Fecha de creación de la orden de servicio:</strong>{" "}
                  {dateFormatHour(samplesData[currentSample]?.fecha_creacion)}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Fecha de toma de muestras:</strong>{" "}
                  {samplesData[currentSample]?.fecha_toma}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Hora de toma de muestras:</strong>{" "}
                  {samplesData[currentSample]?.hora_toma}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>
                    {workLine === "Línea de concretos"
                      ? "Temperatura (°C)"
                      : workLine === "Línea de asfaltos"
                      ? "Temperatura de compactación (°C) "
                      : "Temperatura"}
                    :
                  </strong>{" "}
                  {samplesData[currentSample]?.temperatura}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Cantidad de especimenes:</strong>{" "}
                  {samplesData[currentSample]?.cantidad_especimen}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Localización de la muestra:</strong>{" "}
                  {samplesData[currentSample]?.locacion}
                </Text>
              </Col>
              {workLine === "Línea de concretos" && (
                <>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Código mezcla:</strong>{" "}
                      {samplesData[currentSample]?.codigo_mezcla}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Descripción mezcla:</strong>{" "}
                      {samplesData[currentSample]?.descripcion_mezcla}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Resistencia nominal (MPa):</strong>{" "}
                      {samplesData[currentSample]?.resistencia_nominal}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Slump (Pulgadas "):</strong>{" "}
                      {samplesData[currentSample]?.slum}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Número de remisión :</strong>{" "}
                      {samplesData[currentSample]?.numero_remision}
                    </Text>
                  </Col>
                </>
              )}
              {workLine === "Línea de asfaltos" && (
                <>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Abscisado Inicial:</strong>{" "}
                      {samplesData[currentSample]?.abscisado_inicial}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Abscisado Final:</strong>{" "}
                      {samplesData[currentSample]?.abscisado_final}
                    </Text>
                  </Col>
                </>
              )}

              <Col md={24} xs={24}>
                <Text>
                  <strong>Observación:</strong>{" "}
                  {samplesData[currentSample]?.observacion}
                </Text>
              </Col>
            </Row>
            {/* ---------------------------------------------- Botones de acción --------------------------------------------------------- */}
            <Flex m={"20px 0 0 0"} gap="16px" justify="center">
              {status === "VoBo_laboratorio" && (
                <Flex gap="16px">
                  {getUser().permissions?.includes(8) && (
                    <Button
                      background={theme.colors.lightOrange}
                      hover={theme.colors.orangeHover}
                      onClick={() => handleUpdate(true, false)}
                      style={{ width: "200px" }}
                    >
                      Aprobar muestras
                    </Button>
                  )}
                  {getUser().permissions?.includes(9) && (
                    <Button
                      background={theme.colors.gray}
                      hover={theme.colors.gray3}
                      color={theme.colors.dark}
                      onClick={() => setOpenModal(true)}
                      style={{ width: "200px" }}
                    >
                      Rechazar muestras
                    </Button>
                  )}
                </Flex>
              )}
              {samplesData.length > 0 && status !== "VoBo_laboratorio" && (
                <Button
                  background={theme.colors.lightOrange}
                  hover={theme.colors.orangeHover}
                  onClick={() => exportToExcel()}
                >
                  Exportar orden de servicio
                </Button>
              )}
              <Tooltip
                title={`Ver informe de la muestra ${samplesData[currentSample]?.codigo_laboratorio}.`}
                color={theme.colors.yellow}
              >
                <Button
                  background={theme.colors.lightOrange}
                  hover={theme.colors.orangeHover}
                  onClick={() => openPDF()}
                  disabled={!samplesData[currentSample]?.informe}
                >
                  Ver informe
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  !samplesData[currentSample]?.ensayos_realizar.some(
                    (ele) => ele.codigo_estado === "FZ"
                  )
                    ? `Pendiente finalizar ensayos de la muestra ${samplesData[currentSample]?.codigo_laboratorio}.`
                    : `Enviar ensayos finalizados de la muestra ${samplesData[currentSample]?.codigo_laboratorio}.`
                }
                color={theme.colors.yellow}
              >
                <Button
                  background={theme.colors.lightOrange}
                  hover={theme.colors.orangeHover}
                  onClick={() => handleSendReportEmail()}
                  disabled={
                    !samplesData[currentSample]?.ensayos_realizar.some(
                      (ele) => ele.codigo_estado === "FZ"
                    )
                  }
                >
                  Enviar informe
                </Button>
              </Tooltip>

              {samplesData.length > 1 && (
                <>
                  {currentSample !== 0 && (
                    <Button
                      background={theme.colors.gray}
                      hover={theme.colors.gray3}
                      color={theme.colors.dark}
                      onClick={handleBack}
                      style={{ width: "200px" }}
                    >
                      Muestra anterior
                    </Button>
                  )}
                  {currentSample !== samplesData.length - 1 && (
                    <Button
                      background={theme.colors.lightOrange}
                      hover={theme.colors.orangeHover}
                      onClick={handleNext}
                      style={{ width: "200px" }}
                    >
                      Siguiente muestra
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </>
        </Flex>
      ) : (
        <Flex align="center" justify="center" m="50px 0 0 0">
          <Text size={theme.fonts.size.h5}>
            No se han ingresado muestras en esta orden de servicio.
          </Text>
        </Flex>
      )}
    </Container>
  );
};
