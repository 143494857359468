import React from "react";
import { WrapperMenuMobile } from "./styles";
import menu from "../../../assets/icons/menu.svg";
import logo from "../../../assets/images/logo2.png";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { Flex, Text } from "../../../styles/common";
import { Collapse } from "../../Collapse";
import { getUser } from "../../../utilities/helpers";
import { Button } from "../../Button";
import { theme } from "../../../styles/theme";

export const MenuMobile = ({
  options,
  handleLogout,
  isLoading,
  logout,
  openMenu,
  setOpenMenu,
}) => {
  const navigate = useNavigate();
  const { name, lastName } = getUser();

  const handleHome = () => {
    navigate("/home");
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  return (
    <>
      <Drawer
        placement={"left"}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
      >
        <Flex
          direction="column"
          justify="space-between"
          p="0px 10px"
          height="100%"
        >
          <Collapse panels={options} />
          <Flex justify="space-between" align="center" m="0 0 30px 0">
            <Text>
              Bienvenido {name} {lastName}
            </Text>
            <Button
              type="link"
              background={theme.colors.white}
              style={{ padding: 0 }}
              onClick={handleLogout}
              loading={isLoading}
            >
              <img alt="logout" src={logout} />
            </Button>
          </Flex>
        </Flex>
      </Drawer>
      <WrapperMenuMobile>
        <img
          alt="menu"
          src={menu}
          onClick={handleOpenMenu}
          style={{ cursor: "pointer" }}
        />
        <img
          alt="logo"
          src={logo}
          height={"50px"}
          onClick={handleHome}
          style={{ cursor: "pointer" }}
        />
      </WrapperMenuMobile>
    </>
  );
};
