/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import Table from "../../components/Table";
import Skeleton from "../../components/Skeleton";
import { useNavigate } from "react-router-dom";
import view from "../../assets/icons/view.svg";
import document from "../../assets/icons/document.svg";
import documentDisabled from "../../assets/icons/document-disabled.svg";
import user from "../../assets/icons/user-orange.svg";
import userDisabled from "../../assets/icons/user-disabled.svg";
import { Tooltip } from "antd";
import { Button } from "../../components/Button";
import useGetPendingTest from "./hooks/useGetPendingTest";
import useGetPendingTestFilter from "./hooks/useGetPendingTestFilter";
import Modal from "../../components/Modal";
import useGetOperationalAssistants from "./hooks/useGetOperationalAssistants";
import { Select } from "../../components/Select";
import { getUser, dateFormatHour } from "../../utilities/helpers";
import useSaveResponsible from "./hooks/useSaveResponsible";
import toast from "react-hot-toast";
import { DatePicker } from "antd";
import { useMediaQuery } from "react-responsive";
import useGetClients from "../ServiceOrder/hook/getClients";
import useWorkLine from "../ServiceOrder/hook/useWorkLine";
import { Input } from "../../components/Input";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const Test = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const navigate = useNavigate();
  const saveResponsible = useSaveResponsible();
  const operationalAssistantsList = useGetOperationalAssistants();
  const { data: dataOA, isSuccess: isSuccessOA } = operationalAssistantsList;
  const [operationalAssistantsOptions, setOperationalAssistantsOptions] =
    useState([]);
  const ordersList = useGetPendingTest();
  const { data, isSuccess, isLoading } = ordersList;
  const clientsList = useGetClients();
  const { data: dataClients, isSuccess: isSuccessClients } = clientsList;

  const workLineList = useWorkLine();
  const { data: dataWorkLine, isSuccess: isSuccessWorkLine } = workLineList;

  const [totalItems, setTotalItems] = useState(7);
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [testId, setTestId] = useState(null);
  const [responsible, setResponsible] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [start_date, setStart_date] = useState(null);
  const [end_date, setEnd_date] = useState(null);
  const [client, setClient] = useState(null);
  const [workLine, setWorkLine] = useState(null);
  const [id_order, setId_order] = useState(null);
  const [codigo_muestra, setCodigo_muestra] = useState(null);

  const [workLineOptions, setWorkLineOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const ordersListFilter = useGetPendingTestFilter(
    start_date,
    end_date,
    client,
    workLine,
    id_order,
    codigo_muestra
  );

  const {
    data: dataFilter,
    isSuccess: isSuccessFilter,
    isLoading: isLoadingFilter,
  } = ordersListFilter;

  const exportColumns = [
    "id_orden_servicio",
    "linea_trabajo",
    "cliente",
    "codigo_muestra",
    "nombre_ensayo",
    "cantidad_especimen_ensayo",
    "edad_falla",
    "unidad_tiempo",
    "testigo",
    "estado_ensayo",
    "auxiliares_operativos",
    "fecha_fallo",
  ];

  const exportToExcel = () => {
    const filteredDataTable = dataTable.map((row) => {
      const filteredRow = {};
      Object.keys(row).forEach((key) => {
        if (exportColumns.includes(key)) {
          if (key === "fecha_fallo") {
            filteredRow[key] = dateFormatHour(row[key]);
          } else if (key === "auxiliares_operativos") {
            filteredRow[key] = row[key]
              .map((auxiliar) => `${auxiliar.nombres} ${auxiliar.apellidos}`)
              .join(", ");
          } else {
            filteredRow[key] = row[key];
          }
          // filteredRow[key] = row[key];
          // filteredRow[key] =
          //   key === "fecha_fallo" ? dateFormatHour(row[key]) : row[key];
        }
      });
      return filteredRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredDataTable);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, "Ensayos.xlsx");
  };

  useEffect(() => {
    if (isSuccessClients) {
      setClientsOptions(
        dataClients?.data.map((ele) => ({
          label: ele.proyecto,
          value: ele.proyecto,
        }))
      );
    }
  }, [isSuccessClients]);

  useEffect(() => {
    if (isSuccessWorkLine) {
      setWorkLineOptions(
        dataWorkLine?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.nombre,
        }))
      );
    }
  }, [isSuccessWorkLine]);

  const handleChangeInputIdOrder = (e) => {
    if (e.target.value) {
      setId_order(e.target.value);
    } else {
      setId_order(null);
    }
  };

  const handleChangeInputCodigoMuestra = (e) => {
    if (e.target.value) {
      setCodigo_muestra(`"${e.target.value}"`);
    } else {
      setCodigo_muestra(null);
    }
  };
  const handleChangeSelectClients = (e) => {
    if (e) {
      setClient(`"${e}"`);
    } else {
      setClient(null);
    }
  };

  const handleChangeSelectWorkLineOptions = (e) => {
    if (e) {
      setWorkLine(`"${e}"`);
    } else {
      setWorkLine(null);
    }
  };

  const handleChangeDateStart = (e) => {
    if (e) {
      const year = e.$y;
      const month = e.$M + 1;
      const day = e.$D;
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      setStart_date(`"${formattedDate}"`);
    } else {
      setStart_date(null);
    }
  };

  const handleChangeDateEnd = (e) => {
    if (e) {
      const year = e.$y;
      const month = e.$M + 1;
      const day = e.$D;
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      setEnd_date(`"${formattedDate}"`);
    } else {
      setEnd_date(null);
    }
  };
  const handleDetail = (id, status, line) => {
    navigate(`/service-order/detail/${id}?status=${status}&workLine=${line}`);
  };

  const handleManage = (
    id,
    test,
    amount,
    specification,
    status,
    isSaved,
    workLine
  ) => {
    navigate(
      `/test/manage-test/${id}?test=${test}&amount=${amount}&specification=${specification}&status=${status}&isSaved=${isSaved}&workLine=${workLine}`
    );
  };

  const handleResponsible = (id) => {
    setTestId(id);
    setOpenModal(true);
  };

  useEffect(() => {
    if (isSuccessOA) {
      setOperationalAssistantsOptions(
        dataOA?.data.map((ele) => ({
          value: ele.id,
          label: `${ele.nombres} ${ele.apellidos}`,
        }))
      );
    }
  }, [isSuccessOA]);

  const finishedStatus = ["ER"];

  useEffect(() => {
    if (
      start_date === null &&
      end_date === null &&
      client === null &&
      workLine === null &&
      id_order === null &&
      codigo_muestra === null
    ) {
      if (isSuccess && data?.data.length > 0) {
        const newdataTable = data?.data.map((ele, i) => ({
          ...ele,
          acciones: [
            {
              key: `1${i}`,
              tooltip: finishedStatus.includes(ele.codigo_estado_ensayo)
                ? "Ver ensayo"
                : "Gestionar ensayo",
              icon: ele.activar_boton_realizar_ensayo
                ? document
                : documentDisabled,
              onClick: () =>
                handleManage(
                  ele.id_ensayo_realizar,
                  ele.codigo_ensayo,
                  ele.cantidad_especimen_ensayo,
                  ele.especificacion,
                  ele.codigo_estado_ensayo,
                  ele.flag_ensayo,
                  ele.linea_trabajo
                ),
              disabled: !ele.activar_boton_realizar_ensayo,
            },
            {
              key: `1${i}`,
              tooltip: "Seleccionar responsable",
              icon:
                getUser().permissions.includes(14) &&
                ele.codigo_estado_ensayo !== "PF" &&
                ele.codigo_estado_ensayo !== "FZ"
                  ? user
                  : userDisabled,
              onClick: () => handleResponsible(ele.id_ensayo_realizar),
              disabled:
                !getUser().permissions.includes(14) ||
                ele.codigo_estado_ensayo === "PF" ||
                ele.codigo_estado_ensayo === "FZ",
            },
            {
              key: `1${i}`,
              tooltip: "Detalle",
              icon: view,
              onClick: () =>
                handleDetail(
                  ele.id_orden_servicio,
                  ele.estado_orden_servicio,
                  ele.linea_trabajo
                ),
              disabled: false,
            },
          ],
        }));

        setDataTable(newdataTable);
        setTotalItems(newdataTable.length);
      } else {
        setDataTable([]);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (
      start_date ||
      end_date ||
      client ||
      workLine ||
      id_order ||
      codigo_muestra
    ) {
      ordersListFilter.refetch();
    } else {
      setDataTable([]);
      ordersList.refetch();
    }
  }, [start_date, end_date, client, workLine, id_order, codigo_muestra]);

  useEffect(() => {
    if (
      start_date ||
      end_date ||
      client ||
      workLine ||
      id_order ||
      codigo_muestra
    ) {
      if (isSuccessFilter && dataFilter?.data.length > 0) {
        const newDataTable = dataFilter?.data.map((ele, i) => ({
          ...ele,
          acciones: [
            {
              key: `1${i}`,
              tooltip: finishedStatus.includes(ele.codigo_estado_ensayo)
                ? "Ver ensayo"
                : "Gestionar ensayo",
              icon: ele.activar_boton_realizar_ensayo
                ? document
                : documentDisabled,
              onClick: () =>
                handleManage(
                  ele.id_ensayo_realizar,
                  ele.codigo_ensayo,
                  ele.cantidad_especimen_ensayo,
                  ele.especificacion,
                  ele.codigo_estado_ensayo,
                  ele.flag_ensayo,
                  ele.linea_trabajo
                ),
              disabled: !ele.activar_boton_realizar_ensayo,
            },
            {
              key: `1${i}`,
              tooltip: "Seleccionar responsable",
              icon:
                getUser().permissions.includes(14) &&
                ele.codigo_estado_ensayo !== "PF" &&
                ele.codigo_estado_ensayo !== "FZ"
                  ? user
                  : userDisabled,
              onClick: () => handleResponsible(ele.id_ensayo_realizar),
              disabled:
                !getUser().permissions.includes(14) ||
                ele.codigo_estado_ensayo === "PF" ||
                ele.codigo_estado_ensayo === "FZ",
            },
            {
              key: `1${i}`,
              tooltip: "Detalle",
              icon: view,
              onClick: () =>
                handleDetail(
                  ele.id_orden_servicio,
                  ele.estado_orden_servicio,
                  ele.linea_trabajo
                ),
              disabled: false,
            },
          ],
        }));
        setDataTable(newDataTable);
        setTotalItems(newDataTable.length);
      } else {
        setDataTable([]);
      }
    }
  }, [dataFilter, isSuccessFilter]);

  const columns = [
    {
      title: "N° orden",
      dataIndex: "id_orden_servicio",
      key: "id_orden_servicio",
    },
    {
      title: "Línea de trabajo",
      dataIndex: "linea_trabajo",
      key: "linea_trabajo",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Código muestra",
      dataIndex: "codigo_muestra",
      key: "codigo_muestra",
    },
    {
      title: "Código Ensayo",
      dataIndex: "codigo_ensayo",
      key: "codigo_ensayo",
    },
    {
      title: "Ensayo",
      dataIndex: "nombre_ensayo",
      key: "nombre_ensayo",
    },
    {
      title: "Especimenes",
      dataIndex: "cantidad_especimen_ensayo",
      key: "cantidad_especimen_ensayo",
    },
    {
      title: "Edad de fallo",
      dataIndex: "edad_falla",
      key: "edad_falla",
    },
    {
      title: "Unidad de tiempo",
      dataIndex: "unidad_tiempo",
      key: "unidad_tiempo",
    },
    {
      title: "Testigos",
      dataIndex: "testigo",
      key: "testigo",
    },
    {
      title: "Estado del ensayo",
      dataIndex: "estado_ensayo",
      key: "estado_ensayo",
    },
    {
      title: "Fecha de fallo",
      dataIndex: "fecha_fallo",
      key: "fecha_fallo",
      render: (text) => dateFormatHour(text),
    },
    {
      title: "Responsable(s)",
      dataIndex: "auxiliares_operativos",
      key: "auxiliares_operativos",
      render: (array) =>
        array.map((ele) => (
          <Flex>
            - {ele.nombres} {ele.apellidos}
          </Flex>
        )),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setOpenModal(false);
    setResponsible(null);
    setTestId(null);
    setForceUpdate(!forceUpdate);
  };

  const handleSelectResponsible = (e) => {
    setResponsible(e);
  };

  const handleSaveResponsible = () => {
    saveResponsible.reset();
    saveResponsible.mutate(
      { auxiliar: responsible, ensayo_realizar: testId },
      {
        onSuccess: () => {
          toast.success("Auxiliar asignado exitosamente");
          ordersList.refetch();
          setResponsible(null);
          setOpenModal(false);
        },
      }
    );
  };

  return (
    <Container>
      <Modal
        align="center"
        isOpen={openModal}
        onClose={handleClose}
        padding={36}
        style={{ width: "100px" }}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title="Responsable(s) del ensayo"
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={600}>
          <Select
            value={responsible}
            placeholder={"Selecciona un auxiliar operativo"}
            options={operationalAssistantsOptions}
            onChange={handleSelectResponsible}
          />
        </Modal.Body>
        <Modal.Footer gap="16px" justify="center">
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={handleSaveResponsible}
            loading={saveResponsible.isLoading}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <Text
        size={theme.fonts.size.h4}
        weight={theme.fonts.weight.bold}
        color={theme.colors.dark}
        style={{ textAlign: "left" }}
        mb="20px"
      >
        Ensayos
      </Text>
      <Flex
        gap={isMobile ? "20px" : "60px"}
        direction={isMobile ? "column" : "row"}
        mb="8px"
      >
        <Flex direction="column">
          <Input
            label={"N° orden"}
            placeholder={"Ingrese el N° orden"}
            id="id_order"
            onChange={(e) => handleChangeInputIdOrder(e)}
            width={"100px"}
            type="number"
            min={1}
          />
        </Flex>
        <Flex direction="column">
          <Input
            label={"Código muestra"}
            placeholder={"Ingrese el código de muestra"}
            id="codigo_muestra"
            onChange={(e) => handleChangeInputCodigoMuestra(e)}
            width={"100px"}
            min={1}
          />
        </Flex>
        <Flex direction="column">
          <Select
            label={"Cliente"}
            placeholder={"Seleccione"}
            options={clientsOptions}
            onChange={(e) => handleChangeSelectClients(e)}
            style={{ height: "40px" }}
            width={"350px"}
            id="status_order"
          />
        </Flex>
        <Flex direction="column">
          <Select
            label={"Línea de trabajo"}
            placeholder={"Seleccione"}
            options={workLineOptions}
            onChange={(e) => handleChangeSelectWorkLineOptions(e)}
            style={{ height: "40px" }}
            width={"170px"}
            id="status_order"
          />
        </Flex>
        <Flex direction="column">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.dark}
            weight={theme.fonts.weight.medium}
            mb="8px"
            style={{ textAlign: "start" }}
          >
            Fecha de fallo para la línea de concretos
          </Text>
          <Flex direction="row">
            <DatePicker
              id="start_date"
              onChange={(e) => handleChangeDateStart(e)}
              placeholder="Desde"
              style={{ width: "150px", height: "40px" }}
            />
            <DatePicker
              id="end_date"
              onChange={(e) => handleChangeDateEnd(e)}
              placeholder="Hasta"
              style={{ width: "150px", height: "40px", marginLeft: "30px" }}
            />
          </Flex>
        </Flex>
      </Flex>
      {dataTable.length > 0 && (
        <Flex justify="start">
          <Button
            style={{
              marginTop: "20px",
            }}
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={() => exportToExcel()}
          >
            Exportar busqueda
          </Button>
        </Flex>
      )}
      <br></br>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
        style={{ width: "100%" }}
      />
    </Container>
  );
};
