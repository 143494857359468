/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useGetEquipment from "../../hooks/useGetEquipment";
import { generateNA3Lines } from "../../../../utilities/helpers";
import { Flex, Text } from "../../../../styles/common";
import { Select } from "../../../../components/Select";
import { Input } from "../../../../components/Input";
import { TextArea } from "../../../../components/TextArea";
import { DatePicker } from "antd";
import Table from "../../../../components/Table";
import { theme } from "../../../../styles/theme";
import deleteIcon from "../../../../assets/icons/delete.svg";
import { Button } from "../../../../components/Button";
import useSaveTest from "../../hooks/useSaveTest";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import useSaveAndFinishTest from "../../hooks/useSaveAndFinishTest";
import useGetTest from "../../hooks/useGetTest";
import moment from "moment";
import { getUser } from "../../../../utilities/helpers";

export const NA3 = ({
  testName,
  amount,
  specification,
  id,
  status,
  isSaved,
}) => {
  const navigate = useNavigate();
  const save = useSaveTest();
  const saveAndFinish = useSaveAndFinishTest();
  const { data, isSuccess } = useGetEquipment();
  const [isSavedTest, setIsSavedTest] = useState(false);
  const { data: testData, isSuccess: isSuccessTest } = useGetTest(
    id,
    isSavedTest
  );
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentsName, setEquipmentsNames] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (isSaved === "true") {
      setIsSavedTest(true);
    }
  }, [isSaved]);

  const finishedStatus = ["ER"];

  useEffect(() => {
    const finished = finishedStatus.includes(status);
    setIsFinished(finished);
  }, [status]);

  useEffect(() => {
    setDataTable(
      generateNA3Lines(amount).map((item, index) => ({
        ...item,
        especimen: index + 1,
      }))
    );
  }, [amount]);

  useEffect(() => {
    if (isSuccessTest && testData) {
      setDataTable(
        testData.data.campos_ensayo.map((ele, index) => ({
          key: ele.key,
          especimen: index + 1,
          fecha_ensayo: ele.fecha_ensayo ? moment(ele.fecha_ensayo) : "",
          diametro_cilindro_calibracion: ele.diametro_cilindro_calibracion,
          altura_cilindro_calibracion: ele.altura_cilindro_calibracion,
          masa_seca_cilindro_calibracion: ele.masa_seca_cilindro_calibracion,
          masa_sumergida_cilindro_calibracion:
            ele.masa_sumergida_cilindro_calibracion,
          masa_seca_parafina: ele.masa_seca_parafina,
          masa_sumergida_parafina: ele.masa_sumergida_parafina,
          diametro_1: ele.diametro_1,
          diametro_2: ele.diametro_2,
          diametro_3: ele.diametro_3,
          espesor_1: ele.espesor_1,
          espesor_2: ele.espesor_2,
          espesor_3: ele.espesor_3,
          temperatura_agua: ele.temperatura_agua,
          masa_especimen_seco_aire_a: ele.masa_especimen_seco_aire_a,
          masa_especimen_seco_aire_parafina_b:
            ele.masa_especimen_seco_aire_parafina_b,
          masa_especimen_sumergido_agua_parafina_c:
            ele.masa_especimen_sumergido_agua_parafina_c,
          tiempo_inmersion: ele.tiempo_inmersion,
          temperatura: ele.temperatura,
          humedad: ele.humedad,
          observaciones: ele.observaciones,
          id: 0,
        }))
      );
      setEquipments(testData?.data.equipos_campos_ensayo);

      const equipmentsNames = [];
      testData?.data.equipos_campos_ensayo.forEach((numero) => {
        equipmentOptions.forEach((objeto) => {
          if (objeto.value === numero) {
            equipmentsNames.push(objeto.label);
          }
        });
      });

      setEquipmentsNames(equipmentsNames);
    }
  }, [isSuccessTest, testData, equipmentOptions]);

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      setEquipmentOptions(
        data?.data
          .filter((elem) => elem.linea_trabajo === "Línea de asfaltos")
          .map((ele) => ({
            value: ele.id,
            label: `${ele.nombre} (${ele.codigo})`,
          }))
      );
    }
  }, [isSuccess, data]);

  const handleDate = (event, key) => {
    const newDataTable = [...dataTable];

    newDataTable[key]["fecha_ensayo"] = event;
    setDataTable(newDataTable);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event, key) => {
    const { value, id } = event.target;
    const newDataTable = [...dataTable];

    if (id !== "observaciones") {
      newDataTable[key][id] = Number(value);
    }

    newDataTable[key][id] = value;

    setDataTable(newDataTable);
    setForceUpdate(!forceUpdate);
  };

  const columns = [
    {
      title: "Espécimen",
      dataIndex: "especimen",
      key: "especimen",
      align: "center",
    },
    {
      title: "Fecha de ensayo",
      dataIndex: "fecha_ensayo",
      key: "fecha_ensayo",
      align: "center",
      render: (value, record) => (
        <DatePicker
          placeholder="Selecciona una fecha"
          value={value}
          onChange={(event) => handleDate(event, record.key)}
          style={{ width: "120px", height: "40px" }}
          disabled={isFinished}
        />
      ),
    },
    {
      title: "Cilindro de calibración",
      children: [
        {
          title: "Diámetro [cm]",
          dataIndex: "diametro_cilindro_calibracion",
          key: "diametro_cilindro_calibracion",
          align: "center",
          render: (value, record) => (
            <Input
              id="diametro_cilindro_calibracion"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Altura [cm]",
          dataIndex: "altura_cilindro_calibracion",
          key: "altura_cilindro_calibracion",
          align: "center",
          render: (value, record) => (
            <Input
              id="altura_cilindro_calibracion"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Masa seca [g]",
          dataIndex: "masa_seca_cilindro_calibracion",
          key: "masa_seca_cilindro_calibracion",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_seca_cilindro_calibracion"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Masa sumergida [g]",
          dataIndex: "masa_sumergida_cilindro_calibracion",
          key: "masa_sumergida_cilindro_calibracion",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_sumergida_cilindro_calibracion"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title: "Gravedad especifica parafina",
      children: [
        {
          title: "Masa seca cilindro + parafina [g]",
          dataIndex: "masa_seca_parafina",
          key: "masa_seca_parafina",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_seca_parafina"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Masa sumergida cilindro + parafina [g]",
          dataIndex: "masa_sumergida_parafina",
          key: "masa_sumergida_parafina",
          align: "center",
          render: (value, record) => (
            <Input
              id="masa_sumergida_parafina"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title: "Diámetros del espécimen [cm]",
      children: [
        {
          title: "Diámetro 1",
          dataIndex: "diametro_1",
          key: "diametro_1",
          align: "center",
          render: (value, record) => (
            <Input
              id="diametro_1"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Diámetro 2",
          dataIndex: "diametro_2",
          key: "diametro_2",
          align: "center",
          render: (value, record) => (
            <Input
              id="diametro_2"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Diámetro 3",
          dataIndex: "diametro_3",
          key: "diametro_3",
          align: "center",
          render: (value, record) => (
            <Input
              id="diametro_3"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title: "Espesor del espécimen [cm]",
      children: [
        {
          title: "Espesor 1",
          dataIndex: "espesor_1",
          key: "espesor_1",
          align: "center",
          render: (value, record) => (
            <Input
              id="espesor_1"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Espesor 2",
          dataIndex: "espesor_2",
          key: "espesor_2",
          align: "center",
          render: (value, record) => (
            <Input
              id="espesor_2"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Espesor 3",
          dataIndex: "espesor_3",
          key: "espesor_3",
          align: "center",
          render: (value, record) => (
            <Input
              id="espesor_3"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title: "Temperatura del agua(°C)",
      dataIndex: "temperatura_agua",
      key: "temperatura_agua",
      align: "center",
      render: (value, record) => (
        <Input
          id="temperatura_agua"
          type="number"
          min={0}
          value={value}
          width={"110px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "A: Masa del espécimen seco en el aire [g]",
      dataIndex: "masa_especimen_seco_aire_a",
      key: "masa_especimen_seco_aire_a",
      align: "center",
      render: (value, record) => (
        <Input
          id="masa_especimen_seco_aire_a"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "B: Masa en el aire del especimen seco + parafina [g]",
      dataIndex: "masa_especimen_seco_aire_parafina_b",
      key: "masa_especimen_seco_aire_parafina_b",
      align: "center",
      render: (value, record) => (
        <Input
          id="masa_especimen_seco_aire_parafina_b"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "C: Masa del especimen + parafina sumergido en agua [g]",
      dataIndex: "masa_especimen_sumergido_agua_parafina_c",
      key: "masa_especimen_sumergido_agua_parafina_c",
      align: "center",
      render: (value, record) => (
        <Input
          id="masa_especimen_sumergido_agua_parafina_c"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "Tiempo de inmersión [Minutos]",
      dataIndex: "tiempo_inmersion",
      key: "tiempo_inmersion",
      align: "center",
      render: (value, record) => (
        <Input
          id="tiempo_inmersion"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "Condiciones ambientales del espécimen en el momento del ensayo",
      children: [
        {
          title: "Temperatura (°C)",
          dataIndex: "temperatura",
          key: "temperatura",
          align: "center",
          render: (value, record) => (
            <Input
              id="temperatura"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Humedad relativa(%)",
          dataIndex: "humedad",
          key: "humedad",
          align: "center",
          render: (value, record) => (
            <Input
              id="humedad"
              type="number"
              min={0}
              value={value}
              width={"110px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
      ],
    },
    {
      title:
        "Observaciones (Indicar si hay defectos en el refrentado o en el espécimen)",
      dataIndex: "observaciones",
      key: "observaciones",
      align: "center",
      render: (value, record) => (
        <TextArea
          id="observaciones"
          value={value}
          width={"300px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
  ];

  const handleEquipment = (event) => {
    setEquipments([...equipments, event]);
    setForceUpdate(!forceUpdate);

    if (event !== undefined) {
      const newEquipmentName = equipmentOptions.find(
        (ele) => ele.value === event
      )?.label;

      setEquipmentsNames([...equipmentsName, newEquipmentName]);
      setForceUpdate(!forceUpdate);
    }
  };

  const handleDeleteEquipment = (indx) => {
    const newEquipmentsNames = equipmentsName.filter(
      (ele, index) => index !== indx
    );
    const newEquipments = equipments.filter((ele, index) => index !== indx);
    setEquipments(newEquipments);
    setEquipmentsNames(newEquipmentsNames);
    setForceUpdate(!forceUpdate);
  };

  const addTestLine = () => {
    setDataTable([
      ...dataTable,
      {
        key: dataTable.length,
        especimen: dataTable.length + 1,
        fecha_ensayo: "",
        diametro_cilindro_calibracion: null,
        altura_cilindro_calibracion: null,
        masa_seca_cilindro_calibracion: null,
        masa_sumergida_cilindro_calibracion: null,
        masa_seca_parafina: null,
        masa_sumergida_parafina: null,
        diametro_1: null,
        diametro_2: null,
        diametro_3: null,
        espesor_1: null,
        espesor_2: null,
        espesor_3: null,
        temperatura_agua: null,
        masa_especimen_seco_aire_a: null,
        masa_especimen_seco_aire_parafina_b: null,
        masa_especimen_sumergido_agua_parafina_c: null,
        tiempo_inmersion: null,
        temperatura: null,
        humedad: null,
        observaciones: "",
        id: 0,
      },
    ]);
  };

  const deleteTestLine = () => {
    const newData = dataTable.slice(0, -1);
    setDataTable(newData);
    setForceUpdate(!forceUpdate);
  };

  const handleSave = () => {
    save.reset();
    save.mutate(
      {
        id_ensayo_realizar: Number(id),
        data: {
          equipos_campos_ensayo: [...equipments],
          campos_ensayo: [...dataTable],
          ensayo_realizar: Number(id),
        },
      },
      {
        onSuccess: () => {
          toast.success("Ensayo guardado con éxito");
          navigate("/test");
        },
      }
    );
  };

  const handleSaveAndFinish = () => {
    saveAndFinish.reset();
    saveAndFinish.mutate(
      {
        id_ensayo_realizar: Number(id),
        data: {
          equipos_campos_ensayo: [...equipments],
          campos_ensayo: [...dataTable],
          ensayo_realizar: Number(id),
        },
      },
      {
        onSuccess: () => {
          toast.success("Ensayo guardado y finalizado con éxito");
          navigate("/test");
        },
      }
    );
  };

  return (
    <>
      <Flex direction="column" justify="center" align="center" m="10px 0 0 0">
        <Text weight={theme.fonts.weight.bold}>{testName}</Text>
        <Text weight={theme.fonts.weight.bold}>{specification}</Text>
      </Flex>
      <Flex m="50px 0 0 0" justify="space-between" align="center">
        <Select
          allowClear
          label={"Equipos empleados"}
          options={equipmentOptions}
          placeholder={"Selecciona los equipos empleados"}
          onChange={handleEquipment}
          style={{ marginBottom: "10px" }}
          disabled={isFinished}
        />
      </Flex>
      {equipmentsName.length > 0 &&
        equipmentsName.map((ele, indx) => (
          <Flex width="400px" justify={"space-between"} m="10px 0 0 0">
            <Text>{ele}</Text>
            {!isFinished && (
              <img
                alt="delete"
                src={deleteIcon}
                height={"20px"}
                onClick={() => handleDeleteEquipment(indx)}
                style={{ cursor: "pointer" }}
              />
            )}
          </Flex>
        ))}
      <Flex m="40px 0 0 0" width="100%">
        <Table data={dataTable} columns={columns} pagination={false} />
      </Flex>
      {!isFinished && (
        <>
          <Flex gap="24px" m="20px 0 0 0">
            <Button
              background={theme.colors.orange}
              hover={theme.colors.orangeHover}
              onClick={addTestLine}
            >
              Añadir línea
            </Button>
            <Button
              background={theme.colors.white}
              color={theme.colors.dark}
              hover={
                dataTable.length === Number(amount)
                  ? theme.colors.gray
                  : theme.colors.light3
              }
              onClick={deleteTestLine}
              disabled={dataTable.length === Number(amount)}
            >
              Eliminar línea
            </Button>
          </Flex>
          {getUser().permissions?.includes(15) && (
            <Flex justify="right" m="80px 0 0 0" gap="16px">
              <Button
                background={theme.colors.white}
                color={theme.colors.orange}
                hover={theme.colors.light3}
                border={theme.colors.orange}
                onClick={handleSave}
                isLoading={save.isLoading}
              >
                Guardar
              </Button>
              <Button
                background={theme.colors.orange}
                hover={theme.colors.orangeHover}
                onClick={handleSaveAndFinish}
                isLoading={saveAndFinish.isLoading}
              >
                Guardar y finalizar
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  );
};
