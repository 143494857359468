import { Bar } from "react-chartjs-2";
import useGetDataBarCharts from "../hooks/useGetDataBarChart";
import React, { useState, useEffect } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

export const BarChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
  );

  const { data, isSuccess } = useGetDataBarCharts();
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      setChartData(data?.data);
    }
  }, [isSuccess, data]);

  const titulos = chartData?.map((item) => item.estado_orden__nombre) || [];
  const valores = chartData?.map((item) => item.cantidad_ordenes) || [];

  var misoptions = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "black",
        font: {
          weight: "bold",
        },
        formatter: (value) => value,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 500,
      },
      x: {
        ticks: { color: "#000000" },
      },
    },
  };

  var midata = {
    labels: titulos,
    datasets: [
      {
        label: "Cantidad de órdenes de servicio por estado",
        data: valores,
        backgroundColor: "#ff9c4b",
      },
    ],
  };

  return <div>{chartData && <Bar data={midata} options={misoptions} />}</div>;
};
