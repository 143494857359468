import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Flex, Text } from "../../../styles/common";
import { Controller } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";

export const ModalMixDesign = ({
  openModal,
  handleClose,
  onSubmit,
  formState,
  control,
  watch,
  setValue,
  isLoading,
  isEditing,
  clientOptions,
  specificationsOptions,
  granulometricBandCodesSpecificationOptions,
}) => {
  const { isDirty, isValid, errors } = formState;
  const especificacion = watch("especificacion");
  const [especificacionGuardada, setEspecificacionGuardada] = useState(null);

  useEffect(() => {
    if (especificacion && especificacionGuardada) {
      setValue("codigo_clasificacion", "");
    }
    setEspecificacionGuardada(especificacion);
  }, [especificacion, setValue]);

  return (
    <Modal
      align="center"
      isOpen={openModal}
      onClose={handleClose}
      padding={36}
      width={"min-content"}
    >
      <Modal.Header
        padding="0 0 10px 0"
        title={
          isEditing ? "Actualizar diseño de mezcla" : "Crear diseño de mezcla"
        }
        size={theme.fonts.size.h5}
      />

      <Modal.Body margin="10px 0 10px 0" minHeight={600}>
        <Flex direction="row" justify="center" gap="18px">
          <Controller
            name="especificacion"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={"Especificación"}
                id="especificacion"
                error={!!errors.especificacion}
                helper={
                  errors?.especificacion &&
                  errors?.especificacion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Especificación
                </option>
                {specificationsOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="codigo_clasificacion"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={"Código"}
                id="codigo_clasificacion"
                error={!!errors.codigo_clasificacion}
                helper={
                  errors?.codigo_clasificacion &&
                  errors?.codigo_clasificacion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Código
                </option>
                {granulometricBandCodesSpecificationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="proyecto"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={"Proyecto"}
                id="proyecto"
                error={!!errors.proyecto}
                helper={
                  errors?.proyecto && errors?.proyecto?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Proyecto
                </option>
                {clientOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="estabilidad"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="estabilidad"
                label={"Estabilidad (KN)"}
                placeholder={"Ingresar"}
                error={!!errors.estabilidad}
                type="number"
                min={0}
                helper={
                  errors?.estabilidad &&
                  errors?.estabilidad?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex direction="row" justify="center" gap="18px" m="10px 0 10px 0">
          <Controller
            name="flujo"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="flujo"
                label={"Flujo (mm)"}
                placeholder={"Ingresar"}
                error={!!errors.flujo}
                type="number"
                min={0}
                helper={
                  errors?.flujo && errors?.flujo?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="vacios_aire"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="vacios_aire"
                label={"Vacios con aire(%)"}
                placeholder={"Ingresar"}
                error={!!errors.vacios_aire}
                type="number"
                min={0}
                helper={
                  errors?.vacios_aire &&
                  errors?.vacios_aire?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="vacios_agregado"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="vacios_agregado"
                label={"Vacio en el agregado(%)"}
                placeholder={"Ingresar"}
                error={!!errors.vacios_agregado}
                type="number"
                min={0}
                helper={
                  errors?.vacios_agregado &&
                  errors?.vacios_agregado?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="vacios_asfalto"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="vacios_asfalto"
                label={"Vacios llenos de asfalto (%)"}
                placeholder={"Ingresar"}
                error={!!errors.vacios_asfalto}
                type="number"
                min={0}
                helper={
                  errors?.vacios_asfalto &&
                  errors?.vacios_asfalto?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="relacion_llenante"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="relacion_llenante"
                label={"Relación llenante/Ligante efectivo (--)"}
                placeholder={"Ingresar"}
                error={!!errors.relacion_llenante}
                type="number"
                min={0}
                helper={
                  errors?.relacion_llenante &&
                  errors?.relacion_llenante?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex direction="row" justify="center" gap="18px" m="0 0 10px 0">
          <Controller
            name="relacion_estabilidad"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="relacion_estabilidad"
                label={"Relación estabilidad/Flujo (KN/mm)"}
                placeholder={"Ingresar"}
                error={!!errors.relacion_estabilidad}
                type="number"
                min={0}
                helper={
                  errors?.relacion_estabilidad &&
                  errors?.relacion_estabilidad?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="espesor_promedio"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="espesor_promedio"
                label={"Espesor promedio de la película de asfalto (µm)"}
                placeholder={"Ingresar"}
                error={!!errors.espesor_promedio}
                type="number"
                min={0}
                helper={
                  errors?.espesor_promedio &&
                  errors?.espesor_promedio?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="gravedad_especifica_agregados"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="gravedad_especifica_agregados"
                label={"Gravedad específica de los agregados (--)"}
                placeholder={"Ingresar"}
                error={!!errors.gravedad_especifica_agregados}
                type="number"
                min={0}
                helper={
                  errors?.gravedad_especifica_agregados &&
                  errors?.gravedad_especifica_agregados?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="gravedad_especifica_asfalto"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="gravedad_especifica_asfalto"
                label={"Gravedad específica del asfalto (--)"}
                placeholder={"Ingresar"}
                error={!!errors.gravedad_especifica_asfalto}
                type="number"
                min={0}
                helper={
                  errors?.gravedad_especifica_asfalto &&
                  errors?.gravedad_especifica_asfalto?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Text
          size={theme.fonts.size.h5}
          color={theme.colors.dark}
          weight={theme.fonts.weight.bold}
          style={{ textAlign: "start" }}
        >
          Fórmula de trabajo
        </Text>
        <Flex direction="row" justify="center" gap="18px">
          <Flex direction="column">
            <Text
              size={theme.fonts.size}
              color={theme.colors.dark}
              weight={theme.fonts.weight.medium}
            >
              Abertura de tamiz
            </Text>
            <Text
              size={theme.fonts.size}
              color={theme.colors.dark}
              style={{ margin: "15px 0 0 0" }}
            >
              (11/2") (37,5 mm)
            </Text>
          </Flex>
          <Controller
            name="formula_trabajo_1"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_1"
                label="Fórmula de trabajo"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_1}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_1 &&
                  errors?.formula_trabajo_1?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_1"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_1"
                label="Valor Máx"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_1}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_1 &&
                  errors?.valor_max_1?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_1"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_1"
                label="Valor Min"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_1}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_1 &&
                  errors?.valor_min_1?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "8px 35px 0 0" }}
          >
            (1") (25 mm)
          </Text>
          <Controller
            name="formula_trabajo_2"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_2"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_2}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_2 &&
                  errors?.formula_trabajo_2?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_2"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_2"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_2}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_2 &&
                  errors?.valor_max_2?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_2"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_2"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_2}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_2 &&
                  errors?.valor_min_2?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 25px 0 0" }}
          >
            (3/4") (19 mm)
          </Text>
          <Controller
            name="formula_trabajo_3"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_3"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_3}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_3 &&
                  errors?.formula_trabajo_3?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_3"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_3"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_3}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_3 &&
                  errors?.valor_max_3?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_3"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_3"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_3}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_3 &&
                  errors?.valor_min_3?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 10px 0 0" }}
          >
            (1/2") (12,5 mm)
          </Text>
          <Controller
            name="formula_trabajo_4"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_4"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_4}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_4 &&
                  errors?.formula_trabajo_4?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_4"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_4"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_4}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_4 &&
                  errors?.valor_max_4?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_4"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_4"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_4}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_4 &&
                  errors?.valor_min_4?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 20px 0 0" }}
          >
            (3/8") (9,5 mm)
          </Text>
          <Controller
            name="formula_trabajo_5"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_5"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_5}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_5 &&
                  errors?.formula_trabajo_5?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_5"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_5"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_5}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_5 &&
                  errors?.valor_max_5?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_5"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_5"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_5}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_5 &&
                  errors?.valor_min_5?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 15px 0 0" }}
          >
            (N°4) (4,75 mm)
          </Text>
          <Controller
            name="formula_trabajo_6"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_6"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_6}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_6 &&
                  errors?.formula_trabajo_6?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_6"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_6"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_6}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_6 &&
                  errors?.valor_max_6?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_6"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_6"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_6}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_6 &&
                  errors?.valor_min_6?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 25px 0 0" }}
          >
            (N°10) (2 mm)
          </Text>
          <Controller
            name="formula_trabajo_7"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_7"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_7}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_7 &&
                  errors?.formula_trabajo_7?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_7"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_7"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_7}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_7 &&
                  errors?.valor_max_7?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_7"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_7"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_7}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_7 &&
                  errors?.valor_min_7?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 0 0 0" }}
          >
            (N°40) (0,425 mm)
          </Text>
          <Controller
            name="formula_trabajo_8"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_8"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_8}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_8 &&
                  errors?.formula_trabajo_8?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_8"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_8"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_8}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_8 &&
                  errors?.valor_max_8?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_8"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_8"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_8}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_8 &&
                  errors?.valor_min_8?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px 5px 0 0" }}
          >
            (N°80) (0,18 mm)
          </Text>
          <Controller
            name="formula_trabajo_9"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_9"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_9}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_9 &&
                  errors?.formula_trabajo_9?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_9"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_9"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_9}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_9 &&
                  errors?.valor_max_9?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_9"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_9"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_9}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_9 &&
                  errors?.valor_min_9?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex
          direction="row"
          justify="center"
          gap="18px"
          style={{ margin: "10px 0 0 0" }}
        >
          <Text
            size={theme.fonts.size}
            color={theme.colors.dark}
            style={{ margin: "10px -10px 0 0" }}
          >
            (N°200) (0,075 mm)
          </Text>
          <Controller
            name="formula_trabajo_10"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="formula_trabajo_10"
                placeholder={"Ingresar"}
                error={!!errors.formula_trabajo_10}
                type="number"
                min={0}
                helper={
                  errors?.formula_trabajo_10 &&
                  errors?.formula_trabajo_10?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_max_10"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_max_10"
                placeholder={"Ingresar"}
                error={!!errors.valor_max_10}
                type="number"
                min={0}
                helper={
                  errors?.valor_max_10 &&
                  errors?.valor_max_10?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
          <Controller
            name="valor_min_10"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <Input
                id="valor_min_10"
                placeholder={"Ingresar"}
                error={!!errors.valor_min_10}
                type="number"
                min={0}
                helper={
                  errors?.valor_min_10 &&
                  errors?.valor_min_10?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
                width={"120px"}
              />
            )}
          />
        </Flex>
        <Flex m="30px 0 0 0" align="center" justify="center" gap={"24px"}>
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={
              !isDirty || !isValid
                ? theme.colors.gray
                : theme.colors.orangeHover
            }
            disabled={!isDirty || !isValid}
            isLoading={isLoading}
            onClick={() => {
              const handleJson = () => {
                const formData = {
                  ...watch(),
                };
                delete formData.codigo_clasificacion;
                delete formData.proyecto;
                delete formData.json;
                delete formData.pendiente_completar_info;
                delete formData.especificacion;
                return formData;
              };
              onSubmit({
                codigo_clasificacion: watch("codigo_clasificacion"),
                especificacion: watch("especificacion"),
                proyecto: watch("proyecto"),
                json: handleJson(),
                pendiente_completar_info: false,
              });
            }}
          >
            {isEditing ? "Actualizar" : "Guardar"}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
