import { styled } from "styled-components";
import { theme } from "../../../styles/theme";

export const WrapperMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 0px 30px;
  width: ${(props) => props.width || "360px"};
  min-height: calc(100vh - 70px);
  height: ${(props) => props.height || "min-content"};
  border-radius: 30px;
  background-color: ${theme.colors.white};
  box-shadow: ${(props) => theme.elevation[`z${props.elevation}`]};
  border-radius: ${(props) => props.radius}px;
  justify-content: space-between;
`;

//Menu anterior
// export const WrapperMenu = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 30px 30px 0px 30px;
//   width: ${(props) => props.width || "360px"};
//   min-height: 100vh;
//   height: ${(props) => props.height || "min-content"};
//   border-radius: 30px;
//   background-color: ${theme.colors.white};
//   box-shadow: ${(props) => theme.elevation[`z${props.elevation}`]};
//   border-radius: ${(props) => props.radius}px;
//   justify-content: space-between;
// `;
