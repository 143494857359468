import { useMutation } from "react-query";
import api from "../../../core/api";

const useUpdateEquipment = (id) => {
  const mutation = useMutation((formData) => {
    return api.put(`/ensayo/equipos/${id}/`, formData);
  });
  return mutation;
};
export default useUpdateEquipment;
