/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useGetEquipment from "../../hooks/useGetEquipment";
import { generateNC1Lines } from "../../../../utilities/helpers";
import { Flex, Text } from "../../../../styles/common";
import { Select } from "../../../../components/Select";
import { Input } from "../../../../components/Input";
import { TextArea } from "../../../../components/TextArea";
import { DatePicker, Radio } from "antd";
import Table from "../../../../components/Table";
import { theme } from "../../../../styles/theme";
import deleteIcon from "../../../../assets/icons/delete.svg";
import { Button } from "../../../../components/Button";
import useSaveTest from "../../hooks/useSaveTest";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Link } from "../../../../components/Link";
import Modal from "../../../../components/Modal";
import type1 from "../../../../assets/images/CylinderCompressionType1.png";
import type2 from "../../../../assets/images/CylinderCompressionType2.png";
import type3 from "../../../../assets/images/CylinderCompressionType3.png";
import type4 from "../../../../assets/images/CylinderCompressionType4.png";
import type5 from "../../../../assets/images/CylinderCompressionType5.png";
import type6 from "../../../../assets/images/CylinderCompressionType6.png";
import useSaveAndFinishTest from "../../hooks/useSaveAndFinishTest";
import useGetTest from "../../hooks/useGetTest";
import moment from "moment";
import { getUser } from "../../../../utilities/helpers";

export const NC1 = ({
  testName,
  amount,
  specification,
  id,
  status,
  isSaved,
}) => {
  const navigate = useNavigate();
  const save = useSaveTest();
  const saveAndFinish = useSaveAndFinishTest();
  const { data, isSuccess } = useGetEquipment();
  const [isSavedTest, setIsSavedTest] = useState(false);
  const { data: testData, isSuccess: isSuccessTest } = useGetTest(
    id,
    isSavedTest
  );
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentsName, setEquipmentsNames] = useState([]);
  const [openFailureModal, setOpenFailureModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (isSaved === "true") {
      setIsSavedTest(true);
    }
  }, [isSaved]);

  const finishedStatus = ["ER"];

  useEffect(() => {
    const finished = finishedStatus.includes(status);
    setIsFinished(finished);
  }, [status]);

  // useEffect(() => {
  //   setDataTable(generateCylinderCompressionLines(amount));
  // }, [amount]);

  useEffect(() => {
    setDataTable(
      generateNC1Lines(amount).map((item, index) => ({
        ...item,
        especimen: index + 1,
      }))
    );
  }, [amount]);

  useEffect(() => {
    if (isSuccessTest && testData) {
      setDataTable(
        testData.data.campos_ensayo.map((ele, index) => ({
          key: ele.key,
          especimen: index + 1,
          fecha_rotura: ele.fecha_rotura ? moment(ele.fecha_rotura) : "",
          diametro: ele.diametro,
          dato_1_diametro: ele.dato_1_diametro,
          dato_2_diametro: ele.dato_2_diametro,
          longitud: ele.longitud,
          dato_1_longitud: ele.dato_1_longitud,
          dato_2_longitud: ele.dato_2_longitud,
          masa_cilindro: ele.masa_cilindro,
          carga_maxima: ele.carga_maxima,
          tipo_falla_1: ele.tipo_falla === 1,
          tipo_falla_2: ele.tipo_falla === 2,
          tipo_falla_3: ele.tipo_falla === 3,
          tipo_falla_4: ele.tipo_falla === 4,
          tipo_falla_5: ele.tipo_falla === 5,
          tipo_falla_6: ele.tipo_falla === 6,
          // temperatura: ele.temperatura,
          // humedad: ele.humedad,
          observaciones: ele.observaciones,
          id: 0,
        }))
      );
      setEquipments(testData?.data.equipos_campos_ensayo);

      const equipmentsNames = [];
      testData?.data.equipos_campos_ensayo.forEach((numero) => {
        equipmentOptions.forEach((objeto) => {
          if (objeto.value === numero) {
            equipmentsNames.push(objeto.label);
          }
        });
      });

      setEquipmentsNames(equipmentsNames);
    }
  }, [isSuccessTest, testData, equipmentOptions]);

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      setEquipmentOptions(
        data?.data
          .filter((elem) => elem.linea_trabajo === "Línea de concretos")
          .map((ele) => ({
            value: ele.id,
            label: `${ele.nombre} (${ele.codigo})`,
          }))
      );
    }
  }, [isSuccess, data]);

  const failureTypes = [
    {
      alt: "type-1",
      src: type1,
    },
    {
      alt: "type-2",
      src: type2,
    },
    {
      alt: "type-3",
      src: type3,
    },
    {
      alt: "type-4",
      src: type4,
    },
    {
      alt: "type-5",
      src: type5,
    },
    {
      alt: "type-6",
      src: type6,
    },
  ];

  const handleDate = (event, key) => {
    const newDataTable = [...dataTable];

    newDataTable[key]["fecha_rotura"] = event;
    setDataTable(newDataTable);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event, key) => {
    const { value, id } = event.target;
    const newDataTable = [...dataTable];

    if (id === "dato_1_diametro" || id === "dato_2_diametro") {
      newDataTable[key][id] = Number(value);
      const dato1 = Number(newDataTable[key]["dato_1_diametro"]);
      const dato2 = Number(newDataTable[key]["dato_2_diametro"]);
      const avg = ((dato1 + dato2) / 2).toFixed(2);
      newDataTable[key]["diametro"] = avg;
    }

    if (id === "dato_1_longitud" || id === "dato_2_longitud") {
      newDataTable[key][id] = Number(value);
      const dato1 = Number(newDataTable[key]["dato_1_longitud"]);
      const dato2 = Number(newDataTable[key]["dato_2_longitud"]);
      const avg = ((dato1 + dato2) / 2).toFixed(2);
      newDataTable[key]["longitud"] = avg;
    }

    if (id !== "observaciones") {
      newDataTable[key][id] = Number(value);
    }

    newDataTable[key][id] = value;

    setDataTable(newDataTable);
    setForceUpdate(!forceUpdate);
  };

  const handleRadio = (event, key) => {
    const { checked, id } = event.target;

    const newDataTable = dataTable.map((item, index) => {
      if (index === key) {
        return {
          ...item,
          tipo_falla_1: id === "tipo_falla_1" && checked,
          tipo_falla_2: id === "tipo_falla_2" && checked,
          tipo_falla_3: id === "tipo_falla_3" && checked,
          tipo_falla_4: id === "tipo_falla_4" && checked,
          tipo_falla_5: id === "tipo_falla_5" && checked,
          tipo_falla_6: id === "tipo_falla_6" && checked,
        };
      } else {
        return item;
      }
    });

    setDataTable(newDataTable);
  };

  const columns = [
    {
      title: "Espécimen",
      dataIndex: "especimen",
      key: "especimen",
      align: "center",
    },
    {
      title: "Fecha de rotura",
      dataIndex: "fecha_rotura",
      key: "fecha_rotura",
      align: "center",
      render: (value, record) => (
        <DatePicker
          placeholder="Selecciona una fecha"
          value={value}
          onChange={(event) => handleDate(event, record.key)}
          style={{ width: "120px", height: "40px" }}
          disabled={isFinished}
        />
      ),
    },
    {
      title: "Diámetro [mm]",
      children: [
        {
          title: "Dato 1",
          dataIndex: "dato_1_diametro",
          key: "dato_1_diametro",
          align: "center",
          render: (value, record) => (
            <Input
              id="dato_1_diametro"
              type="number"
              min={0}
              value={value}
              width={"90px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Dato 2",
          dataIndex: "dato_2_diametro",
          key: "dato_2_diametro",
          align: "center",
          render: (value, record) => (
            <Input
              id="dato_2_diametro"
              type="number"
              min={0}
              value={value}
              width={"90px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Promedio",
          dataIndex: "diametro",
          key: "diametro",
          align: "center",
          render: (value, record) => (
            <Input
              id="diametro"
              type="number"
              min={0}
              value={value}
              width={"90px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={true}
            />
          ),
        },
      ],
    },
    {
      title: "Longitud [mm]",
      children: [
        {
          title: "Dato 1",
          dataIndex: "dato_1_longitud",
          key: "dato_1_longitud",
          align: "center",
          render: (value, record) => (
            <Input
              id="dato_1_longitud"
              type="number"
              min={0}
              value={value}
              width={"90px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Dato 2",
          dataIndex: "dato_2_longitud",
          key: "dato_2_longitud",
          align: "center",
          render: (value, record) => (
            <Input
              id="dato_2_longitud"
              type="number"
              min={0}
              value={value}
              width={"90px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={isFinished}
            />
          ),
        },
        {
          title: "Promedio",
          dataIndex: "longitud",
          key: "longitud",
          align: "center",
          render: (value, record) => (
            <Input
              id="longitud"
              type="number"
              min={0}
              value={value}
              width={"90px"}
              onChange={(event) => handleChange(event, record.key)}
              readOnly={true}
            />
          ),
        },
      ],
    },
    {
      title: "Masa del cilindro [Kg]",
      dataIndex: "masa_cilindro",
      key: "masa_cilindro",
      align: "center",
      render: (value, record) => (
        <Input
          id="masa_cilindro"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "Carga máxima [KN]",
      dataIndex: "carga_maxima",
      key: "carga_maxima",
      align: "center",
      render: (value, record) => (
        <Input
          id="carga_maxima"
          type="number"
          min={0}
          value={value}
          width={"90px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
    {
      title: "Tipo de falla",
      children: [
        {
          title: "1",
          dataIndex: "tipo_falla_1",
          key: "tipo_falla_1",
          align: "center",
          render: (value, record) => (
            <Radio
              id="tipo_falla_1"
              checked={value}
              width={"30px"}
              onChange={(event) => handleRadio(event, record.key)}
              disabled={isFinished}
            />
          ),
        },
        {
          title: "2",
          dataIndex: "tipo_falla_2",
          key: "tipo_falla_2",
          align: "center",
          render: (value, record) => (
            <Radio
              id="tipo_falla_2"
              checked={value}
              width={"30px"}
              onChange={(event) => handleRadio(event, record.key)}
              disabled={isFinished}
            />
          ),
        },
        {
          title: "3",
          dataIndex: "tipo_falla_3",
          key: "tipo_falla_3",
          align: "center",
          render: (value, record) => (
            <Radio
              id="tipo_falla_3"
              checked={value}
              width={"30px"}
              onChange={(event) => handleRadio(event, record.key)}
              disabled={isFinished}
            />
          ),
        },
        {
          title: "4",
          dataIndex: "tipo_falla_4",
          key: "tipo_falla_4",
          align: "center",
          render: (value, record) => (
            <Radio
              id="tipo_falla_4"
              checked={value}
              width={"30px"}
              onChange={(event) => handleRadio(event, record.key)}
              disabled={isFinished}
            />
          ),
        },
        {
          title: "5",
          dataIndex: "tipo_falla_5",
          key: "tipo_falla_5",
          align: "center",
          render: (value, record) => (
            <Radio
              id="tipo_falla_5"
              checked={value}
              width={"30px"}
              onChange={(event) => handleRadio(event, record.key)}
              disabled={isFinished}
            />
          ),
        },
        {
          title: "6",
          dataIndex: "tipo_falla_6",
          key: "tipo_falla_6",
          align: "center",
          render: (value, record) => (
            <Radio
              id="tipo_falla_6"
              checked={value}
              width={"30px"}
              onChange={(event) => handleRadio(event, record.key)}
              disabled={isFinished}
            />
          ),
        },
      ],
    },
    // {
    //   title: "Condiciones ambientales del espécimen en el momento del ensayo",
    //   children: [
    //     {
    //       title: "Temperatura (°C)",
    //       dataIndex: "temperatura",
    //       key: "temperatura",
    //       align: "center",
    //       render: (value, record) => (
    //         <Input
    //           id="temperatura"
    //           type="number"
    //           min={0}
    //           value={value}
    //           width={"110px"}
    //           onChange={(event) => handleChange(event, record.key)}
    //           readOnly={isFinished}
    //         />
    //       ),
    //     },
    //     {
    //       title: "Humedad relativa(%)",
    //       dataIndex: "humedad",
    //       key: "humedad",
    //       align: "center",
    //       render: (value, record) => (
    //         <Input
    //           id="humedad"
    //           type="number"
    //           min={0}
    //           value={value}
    //           width={"110px"}
    //           onChange={(event) => handleChange(event, record.key)}
    //           readOnly={isFinished}
    //         />
    //       ),
    //     },
    //   ],
    // },
    {
      title:
        "Observaciones (Indicar si hay defectos en el refrentado o en el espécimen)",
      dataIndex: "observaciones",
      key: "observaciones",
      align: "center",
      render: (value, record) => (
        <TextArea
          id="observaciones"
          value={value}
          width={"300px"}
          onChange={(event) => handleChange(event, record.key)}
          readOnly={isFinished}
        />
      ),
    },
  ];

  const handleEquipment = (event) => {
    setEquipments([...equipments, event]);
    setForceUpdate(!forceUpdate);

    if (event !== undefined) {
      const newEquipmentName = equipmentOptions.find(
        (ele) => ele.value === event
      )?.label;

      setEquipmentsNames([...equipmentsName, newEquipmentName]);
      setForceUpdate(!forceUpdate);
    }
  };

  const handleDeleteEquipment = (indx) => {
    const newEquipmentsNames = equipmentsName.filter(
      (ele, index) => index !== indx
    );
    const newEquipments = equipments.filter((ele, index) => index !== indx);
    setEquipments(newEquipments);
    setEquipmentsNames(newEquipmentsNames);
    setForceUpdate(!forceUpdate);
  };

  const addTestLine = () => {
    setDataTable([
      ...dataTable,
      {
        key: dataTable.length,
        especimen: dataTable.length + 1,
        fecha_rotura: "",
        diametro: null,
        dato_1_diametro: null,
        dato_2_diametro: null,
        longitud: null,
        dato_1_longitud: null,
        dato_2_longitud: null,
        masa_cilindro: null,
        carga_maxima: null,
        // temperatura: null,
        // humedad: null,
        tipo_fallo_1: false,
        tipo_fallo_2: false,
        tipo_fallo_3: false,
        tipo_fallo_4: false,
        tipo_fallo_5: false,
        tipo_fallo_6: false,
        observaciones: "",
        id: 0,
      },
    ]);
  };

  const deleteTestLine = () => {
    const newData = dataTable.slice(0, -1);
    setDataTable(newData);
    setForceUpdate(!forceUpdate);
  };

  const handleSave = () => {
    for (const objeto of dataTable) {
      const tipoFallaKeys = [
        "tipo_falla_1",
        "tipo_falla_2",
        "tipo_falla_3",
        "tipo_falla_4",
        "tipo_falla_5",
        "tipo_falla_6",
      ];
      const tipoFallaTrueIndex = tipoFallaKeys.findIndex(
        (key) => objeto[key] === true
      );
      objeto.tipo_falla = tipoFallaTrueIndex + 1;
      delete objeto.tipo_falla_1;
      delete objeto.tipo_falla_2;
      delete objeto.tipo_falla_3;
      delete objeto.tipo_falla_4;
      delete objeto.tipo_falla_5;
      delete objeto.tipo_falla_6;
    }

    save.reset();
    save.mutate(
      {
        id_ensayo_realizar: Number(id),
        data: {
          equipos_campos_ensayo: [...equipments],
          campos_ensayo: [...dataTable],
          ensayo_realizar: Number(id),
        },
      },
      {
        onSuccess: () => {
          toast.success("Ensayo guardado con éxito");
          navigate("/test");
        },
      }
    );
  };

  const handleSaveAndFinish = () => {
    for (const objeto of dataTable) {
      const tipoFallaKeys = [
        "tipo_falla_1",
        "tipo_falla_2",
        "tipo_falla_3",
        "tipo_falla_4",
        "tipo_falla_5",
        "tipo_falla_6",
      ];
      const tipoFallaTrueIndex = tipoFallaKeys.findIndex(
        (key) => objeto[key] === true
      );
      objeto.tipo_falla = tipoFallaTrueIndex + 1;
      delete objeto.tipo_falla_1;
      delete objeto.tipo_falla_2;
      delete objeto.tipo_falla_3;
      delete objeto.tipo_falla_4;
      delete objeto.tipo_falla_5;
      delete objeto.tipo_falla_6;
    }

    saveAndFinish.reset();
    saveAndFinish.mutate(
      {
        //Campos viejos
        // equipos_campos_ensayo: [...equipments],
        // campos_ensayo: [...dataTable],
        // ensayo_realizar: Number(id),
        id_ensayo_realizar: Number(id),
        data: {
          equipos_campos_ensayo: [...equipments],
          campos_ensayo: [...dataTable],
          ensayo_realizar: Number(id),
        },
      },
      {
        onSuccess: () => {
          toast.success("Ensayo guardado y finalizado con éxito");
          navigate("/test");
        },
      }
    );
  };

  const handleFailureType = () => {
    setOpenFailureModal(true);
  };

  return (
    <>
      <Modal
        align="center"
        isOpen={openFailureModal}
        onClose={() => setOpenFailureModal(false)}
        padding={36}
        width={"1000px"}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title="Tipos de falla"
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={600}>
          <Flex justify="space-around">
            {failureTypes.map((ele, indx) => (
              <Flex key={indx}>
                <img alt={ele.alt} src={ele.src} height={"170px"} />
              </Flex>
            ))}
          </Flex>
        </Modal.Body>
      </Modal>
      <Flex direction="column" justify="center" align="center" m="10px 0 0 0">
        <Text weight={theme.fonts.weight.bold}>{testName}</Text>
        <Text weight={theme.fonts.weight.bold}>{specification}</Text>
      </Flex>
      <Flex m="50px 0 0 0" justify="space-between" align="center">
        <Select
          allowClear
          label={"Equipos empleados"}
          options={equipmentOptions}
          placeholder={"Selecciona los equipos empleados"}
          onChange={handleEquipment}
          style={{ marginBottom: "10px" }}
          disabled={isFinished}
        />
        <Link color={theme.colors.orange} onClick={handleFailureType}>
          Ver tipos de fallas
        </Link>
      </Flex>
      {equipmentsName.length > 0 &&
        equipmentsName.map((ele, indx) => (
          <Flex width="400px" justify={"space-between"} m="10px 0 0 0">
            <Text>{ele}</Text>
            {!isFinished && (
              <img
                alt="delete"
                src={deleteIcon}
                height={"20px"}
                onClick={() => handleDeleteEquipment(indx)}
                style={{ cursor: "pointer" }}
              />
            )}
          </Flex>
        ))}
      <Flex m="40px 0 0 0" width="100%">
        <Table data={dataTable} columns={columns} pagination={false} />
      </Flex>
      {!isFinished && (
        <>
          <Flex gap="24px" m="20px 0 0 0">
            <Button
              background={theme.colors.orange}
              hover={theme.colors.orangeHover}
              onClick={addTestLine}
            >
              Añadir línea
            </Button>
            <Button
              background={theme.colors.white}
              color={theme.colors.dark}
              hover={
                dataTable.length === Number(amount)
                  ? theme.colors.gray
                  : theme.colors.light3
              }
              onClick={deleteTestLine}
              disabled={dataTable.length === Number(amount)}
            >
              Eliminar línea
            </Button>
          </Flex>
          {getUser().permissions?.includes(15) && (
            <Flex justify="right" m="80px 0 0 0" gap="16px">
              <Button
                background={theme.colors.white}
                color={theme.colors.orange}
                hover={theme.colors.light3}
                border={theme.colors.orange}
                onClick={handleSave}
                isLoading={save.isLoading}
              >
                Guardar
              </Button>
              <Button
                background={theme.colors.orange}
                hover={theme.colors.orangeHover}
                onClick={handleSaveAndFinish}
                isLoading={saveAndFinish.isLoading}
              >
                Guardar y finalizar
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  );
};
