import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetResultsConcrete = (id, isReady) => {
  const data = useQuery(
    ["useGetResultsConcrete", id, isReady],
    () => api.get(`/ensayo/datos-ensayo-informe/?ensayo_realizar_id=${id}`),
    { refetchOnWindowFocus: false, enabled: isReady }
  );

  return data;
};

export default useGetResultsConcrete;
