import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetMixDesign = (projects) => {
  const data = useQuery(["useGetMixDesign", projects], () =>
    api.get(`/orden_servicio/tipo_clasificacion/`)
  );

  return data;
};

export default useGetMixDesign;
